import { useEffect, useState } from "react";
import Form from "../Form";
import { useDispatch, useSelector } from "react-redux";
import { growthSelector, jobSelector } from "../../redux/selectors";
import { setGrowth } from "../../redux/actions/growth";
import { growthQuestions } from "../../utils/constants";

const Growth = () => {
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const { growth } = useSelector(growthSelector);
  const { job } = useSelector(jobSelector);

  const handleChange = (formData: any, isValid: boolean) => {
    dispatch(
      setGrowth({
        ...growth,
        formData: { ...growth.formData, ...formData, jobId: job.id },
        isConfigured: isValid,
      })
    );
  };

  useEffect(() => {
    if (!growth.isConfigured) {
      setError("You need to select atleast one preference from each section");
    } else {
      setError("");
    }
  }, [growth.isConfigured]);

  return (
    <>
      <p className="form-title">Growth</p>
      <Form
        id="growth"
        questions={growthQuestions}
        handleChange={handleChange}
        formData={growth.formData}
      />
      <p className="form-error-message">{error}</p>
    </>
  );
};

export default Growth;
