// Company
export const companyQuestions = [
  {
    question: "What is the size of the company offering this job?",
    hasSingleOption: false,
    disableField: "companySize",
    otherField: "otherCompanySize",
    otherFieldText: "otherCompanySizeText",
    options: [
      {
        fieldName: "startupsFunded",
        name: "Startup (Funded) (1-10)",
      },
      {
        fieldName: "startupsBootstraped",
        name: "Startup (Bootstraped/Angel Funded) (10-50)",
      },
      {
        fieldName: "midlevelCompany",
        name: "Mid Level Company (50-200)",
      },
      {
        fieldName: "earlyStage",
        name: "Early Stage Scaling Company (200-500)",
      },
      {
        fieldName: "lateStage",
        name: "Late Stage Growth Company (500-1000)",
      },
      {
        fieldName: "mnc",
        name: "MNC (1000+)",
      },
    ],
  },
  {
    question: "What type of company is offering this job?",
    hasSingleOption: false,
    disableField: "companyType",
    otherField: "otherCompany",
    otherFieldText: "otherCompanyText",
    options: [
      {
        fieldName: "productBased",
        name: "Product Based",
      },
      {
        fieldName: "serviceBased",
        name: "Service Based",
      },
      {
        fieldName: "hybrid",
        name: "Hybrid",
      },
    ],
  },
  {
    question: "What kind of office can the company provide for this job?",
    hasSingleOption: false,
    disableField: "companyKind",
    otherField: "otherOffice",
    otherFieldText: "otherOfficeText",
    options: [
      {
        fieldName: "hugeOffice",
        name: "Huge Office",
      },
      {
        fieldName: "mediumOffice",
        name: "Medium Office",
      },
      {
        fieldName: "smallOffice",
        name: "Small Office",
      },
      {
        fieldName: "campus",
        name: "Campus",
      },
    ],
  },
];

// Growth
export const growthQuestions = [
  {
    question: "What kind of growth can the job offer?",
    hasSingleOption: false,
    disableField: "growthKind",
    otherField: "otherKindOfGrowth",
    otherFieldText: "otherKindOfGrowthText",
    options: [
      {
        fieldName: "growthInKnownTechnologies",
        name: "Challenging Projects/Work in 'known' technologies",
      },
      {
        fieldName: "growthInNewTechnology",
        name: "Challenging Projects/Work in 'new' technology",
      },
      {
        fieldName: "scopeToLearnNewSkills",
        name: "Scope to learn new skills",
      },
      {
        fieldName: "salaryHike",
        name: "Hike in Salary",
      },
      {
        fieldName: "seniorLevels",
        name: "Promotion to senior levels",
      },
    ],
  },
  {
    question: "Does the company support any learning for this job? and how?",
    hasSingleOption: false,
    disableField: "growthSupport",
    otherField: "otherlevelOfSupport",
    otherFieldText: "otherLevelOfSupportText",
    options: [
      {
        fieldName: "reimburseMyLearningBills",
        name: "Reimburse learning bills",
      },
      {
        fieldName: "taxBenefitsForLearning",
        name: "Provide tax benefits for learning",
      },
      {
        fieldName: "provideLMS",
        name: "Provide LMS (Learning Management System)",
      },
    ],
  },
  {
    question: "How much hike can you provide during the appraisal?",
    fieldName: "hikeInAppraisal",
    hasSlider: true,
    disableField: "growthHike",
    marks: [
      {
        value: 10,
        label: "10%",
      },
      {
        value: 30,
        label: "30%",
      },
      {
        value: 60,
        label: "60%",
      },
      {
        value: 100,
        label: "100%",
      },
    ],
    min: 10,
    max: 100,
    defaultRange: [20, 40],
    steps: 10,
    unit: "%",
  },
];

// Perks
export const perksQuestions = [
  {
    question: "What kind of perks does the company offer?",
    hasSingleOption: false,
    disableField: "perksKind",
    otherField: "otherPerks",
    otherFieldText: "otherPerksText",
    options: [
      {
        fieldName: "foodCoupons",
        name: "Food Coupons",
      },
      {
        fieldName: "internetReimbursement",
        name: "Internet Reimbursement",
      },
      {
        fieldName: "relocationAllowance",
        name: "Relocation Allowance",
      },
      {
        fieldName: "telephoneBillReimbursement",
        name: "Telephone Bill Reimbursement",
      },
      {
        fieldName: "gadgetBillReimbursement",
        name: "Gadget Bill Reimbursement",
      },
      {
        fieldName: "certificationReimbursement",
        name: "Certification Reimbursement",
      },
      {
        fieldName: "booksPeriodicalsReimbursement",
        name: "Books & Periodicals Reimbursement",
      },

      {
        fieldName: "higherEducation",
        name: "Higher Education",
      },
      {
        fieldName: "wellness",
        name: "Wellness",
      },
      {
        fieldName: "transport",
        name: "Transport",
      },
      {
        fieldName: "onsite",
        name: "Onsite",
      },
    ],
  },
  {
    question:
      "What additional facilities does the company provide for this job?",
    hasSingleOption: false,
    disableField: "perksAdditionalFacilities",
    otherField: "otherFacilities",
    otherFieldText: "otherFacilitiesText",
    options: [
      {
        fieldName: "playPen",
        name: "Play Pen/Creche/Child Care",
      },
      {
        fieldName: "playground",
        name: "Playground",
      },
      {
        fieldName: "basketball",
        name: "Basketball",
      },
      {
        fieldName: "kitchen",
        name: "Kitchen/Canteen",
      },
      {
        fieldName: "gym",
        name: "Gym",
      },
      {
        fieldName: "yoga",
        name: "Yoga/Meditation",
      },
    ],
  },
];

// Role
export const roleQuestions = [
  {
    question: "What designation/title do you provide for this job?",
    hasSingleOption: true,
    fieldName: "roleType",
    disableField: "preferredRole",
    otherField: "otherRoleType",
    otherFieldText: "otherRoleTypeText",
    options: [
      {
        fieldName: "individualContributor",
        name: "Individual Contributor",
      },
      {
        fieldName: "techLead",
        name: "Technology Leader/Manager",
      },
      {
        fieldName: "peopleManager",
        name: "People Manager",
      },
      {
        fieldName: "functionalLead",
        name: "Functional Lead/Manager",
      },
    ],
  },
  {
    question: "What kind of employment do you offer for this job?",
    hasSingleOption: true,
    fieldName: "employmentType",
    disableField: "preferredEmployment",
    otherField: "otherEmploymentType",
    otherFieldText: "otherEmploymentTypeText",
    options: [
      {
        fieldName: "contractor",
        name: "Contractor",
      },
      {
        fieldName: "freelancer",
        name: "Freelancer",
      },
      {
        fieldName: "fullTime",
        name: "Full Time",
      },
      {
        fieldName: "partTime",
        name: "Part Time",
      },
      {
        fieldName: "contractToHire",
        name: "Contract to Hire",
      },
    ],
  },
  {
    question: "What designation/title can you provide for this job?",
    hasSingleOption: true,
    fieldName: "designationType",
    disableField: "preferredDesignation",
    otherField: "otherDesignationType",
    otherFieldText: "otherDesignationTypeText",
    options: [
      {
        fieldName: "aboveCurrentDesignation",
        name: "Can offer designation above the current designation of the job seeker",
      },
      {
        fieldName: "sameDesignation",
        name: "Will provide the same designation of the job seeker",
      },
      {
        fieldName: "cannotAlter",
        name: "Cannot alter the designation for the job seeker",
      },
      {
        fieldName: "newTitle",
        name: "We can create a new designation/title of choice for the job seeker ",
      },
    ],
  },
];

// Salary
export const salaryQuestions = [
  {
    question: "What is the salary budget for this job?",
    fieldName: "salaryRange",
    hasSlider: true,
    disableField: "salaryRangeDisabled",
    marks: [
      {
        value: 1,
        label: "1 LPA",
      },
      {
        value: 30,
        label: "30 LPA",
      },
      {
        value: 60,
        label: "60 LPA",
      },
      {
        value: 100,
        label: "100 LPA",
      },
    ],
    min: 0,
    max: 100,
    defaultRange: [10, 15],
    steps: 1,
    unit: "LPA",
  },
  {
    question: "How much hike can you give for this job?",
    fieldName: "salaryHike",
    hasSlider: true,
    disableField: "salaryHikeDisabled",
    marks: [
      {
        value: 10,
        label: "10%",
      },
      {
        value: 30,
        label: "30%",
      },
      {
        value: 60,
        label: "60%",
      },
      {
        value: 100,
        label: "100%",
      },
    ],
    min: 10,
    max: 100,
    defaultRange: [20, 30],
    steps: 10,
    unit: "%",
  },
  {
    question: "Select the salary components you wish to offer for this job",
    hasSingleOption: false,
    disableField: "salaryComponents",
    otherField: "otherSalaryComponent",
    otherFieldText: "otherSalaryComponentText",
    options: [
      {
        fieldName: "fixedPay",
        name: "Fixed Pay",
      },
      {
        fieldName: "variablePay",
        name: "Variable Pay",
      },
      {
        fieldName: "bonus",
        name: "Bonus",
      },
      {
        fieldName: "esop",
        name: "ESOP",
      },
      {
        fieldName: "joiningBonus",
        name: "Joining Bonus",
      },
    ],
  },
];

// Work
export const workQuestions = [
  {
    question: "What are the work timings for this job?",
    hasSingleOption: false,
    disableField: "workTime",
    otherField: "otherTiming",
    otherFieldText: "otherTimingText",
    options: [
      {
        fieldName: "straightHours",
        name: "8 hours straight",
      },
      {
        fieldName: "intermittentWorkWithBreaks",
        name: "Intermittent work with breaks",
      },
      {
        fieldName: "atOwnConvenience",
        name: "At own convenience",
      },
      {
        fieldName: "flexible",
        name: "Flexible",
      },
      {
        fieldName: "taskBased",
        name: "Task Based",
      },
    ],
  },
  {
    question: "Which shift does the job offer?",
    hasSingleOption: false,
    disableField: "workShift",
    otherField: "otherShift",
    otherFieldText: "otherShiftText",
    options: [
      {
        fieldName: "dayShift",
        name: "Day Shift",
      },
      {
        fieldName: "nightShift",
        name: "Night Shift",
      },
      {
        fieldName: "afternoonShift",
        name: "Afternoon Shift",
      },
    ],
  },
  {
    question:
      "What is the work environment at your company? What is the effort required for this job?",
    hasSingleOption: false,
    disableField: "workEnvironment",
    otherField: "otherWork",
    otherFieldText: "otherWorkText",
    options: [
      {
        fieldName: "challengingWork",
        name: "Challenging Work",
      },
      {
        fieldName: "hardWork",
        name: "Long work hours - hard work",
      },
      {
        fieldName: "relaxedWork",
        name: "Relaxed Work",
      },
      {
        fieldName: "lowStressWork",
        name: "Low stress work",
      },
    ],
  },
  {
    question: "What kind of work does this job provide?",
    hasSingleOption: false,
    disableField: "workKind",
    otherField: "otherKindOfWork",
    otherFieldText: "otherKindOfWorkText",
    options: [
      {
        fieldName: "rd",
        name: "R & D",
      },
      {
        fieldName: "clientBasedProjects",
        name: "Client based Projects/Products",
      },
      {
        fieldName: "internalProjects",
        name: "Internal Projects/Products",
      },
      {
        fieldName: "maintenanceAndSupport",
        name: "Maintenance and Support",
      },
    ],
  },
];
