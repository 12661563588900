const mappings = {
  // Company
  "Startups_Funded_1-10": "Startup (Funded) (1-10)",
  "Startups_BootstrapedAngel_Funded_10-50":
    "Startup (Bootstraped/Angel Funded) (10-50)",
  "Midlevel_Company_50-200": "Midlevel Company (50-200)",
  "Early_Stage_Scaling_Companies_200-500":
    "Early Stage Scaling Company (200-500)",
  "Late_Stage_Growth_Companies_500-1000": "Late Stage Growth Company (500-100)",
  MNC_1000: "MNC (1000+)",
  Any_company_is_fine_size: "Any company size is fine",
  Not_sure_company_size: "Not sure about company size",
  Product_Based: "Product Based",
  Service_Based: "Service Based",
  Hybrid: "Hybrid",
  Any_company_is_fine_type: "Any company type is fine",
  Not_sure_type: "Not sure about company type",
  Huge_Office: "Huge Office",
  Medium_Office: "Medium Office",
  Small_Office: "Small Office",
  Campus: "Campus",
  Any_office_is_fine: "Any office size is fine",
  Not_sure_office: "Not sure about office size",

  // Growth
  Challenging_ProjectsWork_in_known_technologies:
    "Challenging Projects/Work in 'known' technologies",
  Challenging_ProjectsWork_in_new_technology:
    "Challenging Projects/Work in 'new' technology",
  Scope_to_learn_new_skills: "Scope to learn new skills",
  Hike_in_Salary: "Hike in salary",
  Promotion_to_senior_levels: "Promotion to senior levels",
  Any_growth_is_fine: "Any type of growth is fine",
  Not_sure_growth: "Not sure about growth type",
  Reimburse_my_learning_bills: "Reimburse learning bills",
  Provide_LMS_Learning_Management_System:
    "Provide LMS (Learning Management System)",
  Provide_tax_benefits_for_learning: "Provide tax benefits for learning",
  Any_level_of_support_is_fine: "Any level of support is fine",
  Not_sure_support: "Not sure about support",

  // Perks
  Food_Coupons: "Food Coupons",
  Internet_Reimbursement: "Internet Reimbursement",
  Relocation_Allowance: "Relocation Allowance",
  Telephone_Bill_Reimbursement: "Telephone Bill Reimbursement",
  Gadget_Bill_Reimbursement: "Gadget Bill Reimbursement",
  Certification_Reimbursement: "Certification Reimbursement",
  Books__Periodicals_Reimbursement: "Books & Periodicals Reimbursement",
  Higher_Education: "Higher Education",
  Wellness: "Wellness",
  Transport: "Transport",
  Onsite: "Onsite",
  Any_perk_is_fine: "Any perk is fine",
  Not_sure_perk: "Not sure about perks",
  Play_PenCrecheChild_Care: "Play Pen/Creche/Child Care",
  Basket_ball: "Basketball",
  Gym: "Gym",
  Playground: "Playground",
  KitchenCanteen: "Kitchen/Canteen",
  YogaMeditation: "Yoga/Meditation",
  Any_facility_is_fine: "Any facility is fine",
  Not_sure_facility: "Not sure about facilities",

  // Role
  individualContributor: "Individual Contributor",
  techLead: "Technology Leader/Manager",
  peopleManager: "People Manager",
  functionalLead: "Functional Lead/Manager",
  anyRoleIsFine: "Any role is fine",
  notSure: "Not sure about role",
  contractor: "Contractor",
  freelancer: "Freelancer",
  fullTime: "Full Time",
  partTime: "Part Time",
  contractToHire: "Contract to Hire",
  anyEmploymentIsFine: "Any type of employment is fine",
  employmentTypeNotSure: "Not sure about employment type",
  aboveCurrentDesignation:
    "Can offer designation above the current designation of the job seeker",
  sameDesignation: "Will provide the same designation of the job seeker",
  cannotAlter: "Cannot alter the designation for the job seeker",
  newTitle:
    "We can create a new designation/title of choice for the job seeker ",

  // Salary
  Fixed_Pay: "Fixed Pay",
  Variable_Pay: "Variable Pay",
  Bonus: "Bonus",
  ESOP: "ESOP",
  Joining_Bonus: "Joining Bonus",
  Any_component_is_fine: "Any salary component is fine",
  Not_sure: "Not sure about salary components",

  // Work
  "8_hours_straight": "8 hours straight",
  Flexible: "Flexible",
  Intermittent_work_with_breaks: "Intermittent work with breaks",
  Task_based: "Task based",
  At_my_own_convenience: "At own convenience",
  Any_timing_is_fine: "Any work timings is fine",
  Not_sure_time: "Not sure about working time",
  Day_Shift: "Day Shift",
  Night_Shift: "Night Shift",
  Afternoon_Shift: "Afternoon Shift",
  Any_shift_is_fine: "Any shift is fine",
  Not_sure_shift: "Not sure about shift",
  Challenging_Work: "Challenging work",
  Relaxed_Work: "Relaxed work",
  "Long_work_hours_-_hard_work": "Long work hours - hard work",
  Low_stress_work: "Low stress work",
  Any_kind_of_work_is_fine: "Any kind of work is fine",
  Not_sure_work_type: "Not sure about work type",
  R__D: "R & D",
  Internal_ProjectsProducts: "Internal Projects/Products",
  Client_based_ProjectsProducts: "Client based Projects/Products",
  Maintenance_and_Support: "Maintenance & Support",
  Any_type_of_work_is_fine: "Any type of work is fine",
  Not_sure_type_of_work: "Not sure about type of work",
  anyIndustry: "Any Industry",
};

export default mappings;
