import { useEffect, useState } from "react";
import moment from "moment";
import axios from "../../utils/axios";
import { useDispatch } from "react-redux";
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "../Button";
import CreateJob from "../CreateJob";
import JobListingDetails from "../JobListingDetails";
import { setJob } from "../../redux/actions/job";
import { setCompany } from "../../redux/actions/company";
import { setGrowth } from "../../redux/actions/growth";
import { setLocation } from "../../redux/actions/location";
import { setPerks } from "../../redux/actions/perks";
import { setRole } from "../../redux/actions/role";
import { setSalary } from "../../redux/actions/salary";
import { setWork } from "../../redux/actions/work";
import { setCredits } from "../../redux/actions/credits";
import { XANO_API_BASE_URL } from "../../utils/api-urls";
import Dialog from "../Dialog";
import "./styles.css";

const TrackJobs = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const jobsList: any = [];

  const [jobs, setJobs] = useState<any[]>([]);

  const [selectedJobId, setSelectedJobId] = useState("");
  const [selectedJobTitle, setSelectedJobTitle] = useState("");
  const [selectedJobDescription, setSelectedJobDescription] = useState("");
  const [selectedClientName, setSelectedClientName] = useState("");
  const [invitesSent, setInvitesSent] = useState(0);
  const [createdDate, setCreatedDate] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDetailsMode, setIsDetailsMode] = useState(false);
  const [isClosed, setIsClosed] = useState(false);

  const { fetch } = props;
  const [reload, setReload] = useState(fetch ? true : false);

  const [cloning, setCloning] = useState(false);

  const dispatch = useDispatch();

  const employerData = JSON.parse(
    localStorage.getItem("employerData")
      ? localStorage.getItem("employerData") || "{}"
      : "{}"
  );

  const fetchJobs = async () => {
    try {
      setError("");
      setLoading(true);
      const jobsResponse: any = await axios.get(
        `${XANO_API_BASE_URL}/jp_job_seeker_preferences/all_job_details?employerId=${employerData.id}`
      );
      jobsResponse.data.forEach((job: any) => {
        if (job.status === "Active") {
          jobsList.push({
            id: job["jobId"],
            title: job["job_title"],
            description: job["Description"],
            clientName: job["Client_Name"],
            invitesSent: job["invites_sent"],
            responded: job["js_responded"],
            notResponded: job["invites_sent"] - job["js_responded"],
            status: job["status"],
            createdOn: job["created_at"],
            creditsSpent: job["invites_sent"] * 20,
          });
        } else {
          jobsList.push({
            id: job["jobId"],
            title: job["job_title"],
            description: job["Description"],
            clientName: job["Client_Name"],
            invitesSent: 0,
            responded: "NA",
            notResponded: "NA",
            status: job["status"],
            createdOn: job["created_at"],
            creditsSpent: "NA",
          });
        }
      });
      setJobs([...jobsList]);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setError(
        "An error occured while fetching the jobs. Please try again later"
      );
    }
  };

  useEffect(() => {
    (async () => {
      fetchJobs();
      setReload(false);
    })();
  }, [reload]);

  const resetData = () => {
    dispatch(setJob({}));
    dispatch(setCompany({}));
    dispatch(setGrowth({}));
    dispatch(setLocation({}));
    dispatch(setPerks({}));
    dispatch(setRole({}));
    dispatch(setSalary({}));
    dispatch(setWork({}));
    dispatch(setCredits({}));
  };

  const handleEdit = (
    id: string,
    title: string,
    description: string,
    clientName: string,
    invitesSent: number
  ) => {
    resetData();
    setSelectedJobId(id);
    setSelectedJobTitle(title);
    setSelectedJobDescription(description);
    setSelectedClientName(clientName);
    setInvitesSent(invitesSent);
    setIsEditMode(true);
  };

  const handleClone = async (
    id: string,
    jobTitle: string,
    jobDescription: string,
    clientName: string
  ) => {
    try {
      setSelectedJobTitle(jobTitle);
      setCloning(true);
      jobTitle = `${jobTitle} - Clone`;

      const response = await axios.post(
        `${XANO_API_BASE_URL}/emp_prefs/create_job`,
        {
          emp_id: employerData.id,
          job_title: jobTitle,
          Description: jobDescription,
          Client_Name: clientName,
        }
      );
      const createdJobId = response.data["job_id"];
      dispatch(
        setJob({
          id: response.data["job_id"],
          jobTitle,
          jobDescription,
          clientName,
        })
      );
      axios
        .get(`${XANO_API_BASE_URL}/emp_prefs/get_prefs_by_job_id?jobId=${id}`)
        .then((response) => {
          const data: any = response.data;
          const company = data.company;
          const growth = data.growth;
          const perks = data.perks;
          const location = data.location;
          const role = data.role;
          const salary = data.salary;
          const work = data.work;
          axios
            .all([
              axios.post(`${XANO_API_BASE_URL}/jp_emp_preferences/company`, {
                company: { ...company, jobId: createdJobId },
                disabled: {
                  companySize: data.disabled_questions["companySize"],
                  companyKind: data.disabled_questions["companyKind"],
                  companyType: data.disabled_questions["companyType"],
                },
              }),
              axios.post(`${XANO_API_BASE_URL}/jp_emp_preferences/growth`, {
                growth: { ...growth, jobId: createdJobId },
                disabled: {
                  growthKind: data.disabled_questions["growthKind"],
                  growthSupport: data.disabled_questions["growthSupport"],
                  growthHike: data.disabled_questions["growthHike"],
                },
              }),
              axios.post(`${XANO_API_BASE_URL}/jp_emp_preferences/location`, {
                location: { ...location, jobId: createdJobId },
                disabled: {
                  locPreferences: data.disabled_questions["locPreferences"],
                  locPreferredCities:
                    data.disabled_questions["locPreferredCities"],
                  locPreferredMode: data.disabled_questions["locPreferredMode"],
                  locPreferredCountries:
                    data.disabled_questions["locPreferredCountries"],
                },
              }),
              axios.post(`${XANO_API_BASE_URL}/jp_emp_preferences/perks`, {
                perks: { ...perks, jobId: createdJobId },
                disabled: {
                  perksKind: data.disabled_questions["perksKind"],
                  perksAdditionalFacilities:
                    data.disabled_questions["perksAdditionalFacilities"],
                },
              }),
              axios.post(`${XANO_API_BASE_URL}/jp_emp_preferences/role`, {
                role: { ...role, jobId: createdJobId },
                disabled: {
                  preferredRole: data.disabled_questions["preferredRole"],
                  preferredEmployment:
                    data.disabled_questions["preferredEmployment"],
                  preferredDesignation:
                    data.disabled_questions["preferredDesignation"],
                },
              }),
              axios.post(`${XANO_API_BASE_URL}/jp_emp_preferences/salary`, {
                salary: { ...salary, jobId: createdJobId },
                disabled: {
                  salaryRangeDisabled: data.disabled_questions["salaryRange"],
                  salaryHikeDisabled: data.disabled_questions["salaryHike"],
                  salaryComponents: data.disabled_questions["salaryComponents"],
                },
              }),
              axios.post(`${XANO_API_BASE_URL}/jp_emp_preferences/work`, {
                work: { ...work, jobId: createdJobId },
                disabled: {
                  workTime: data.disabled_questions["workTime"],
                  workShift: data.disabled_questions["workShift"],
                  workEnvironment: data.disabled_questions["workEnvironment"],
                  workKind: data.disabled_questions["workKind"],
                  industryDisable: data.disabled_questions["Industry"],
                },
              }),
            ])
            .then(
              axios.spread((resp) => {
                fetchJobs()
                  .then(() => {
                    setCloning(false);
                  })
                  .catch((error) => {
                    setCloning(false);
                    console.log(error);
                    setError(
                      "There was an error cloning the job. Please try again later"
                    );
                  });
              })
            )
            .catch((error) => {
              setCloning(false);
              console.log(error);
              setError(
                "There was an error cloning the job. Please try again later"
              );
            });
        });
    } catch (err) {
      setCloning(false);
      setError("There was an error cloning the job. Please try again later");
    }
  };

  function Row(props: any) {
    const { row } = props;

    return (
      <>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <div className="drafts-action-buttons-container">
              <div
                title="Edit Job"
                onClick={() => {
                  if (row.status !== "Closed") {
                    handleEdit(
                      row.id,
                      row.title,
                      row.description,
                      row.clientName,
                      row.invitesSent
                    );
                  }
                }}
              >
                <EditIcon
                  sx={{
                    fontSize: 22,
                    cursor: row.status === "Closed" ? "default" : "pointer",
                    marginRight: "0.5rem",
                  }}
                  color={row.status === "Closed" ? "disabled" : "action"}
                />
              </div>
              <div
                title="Clone an Active Job"
                onClick={() => {
                  // if (row.status === "Active") {
                  handleClone(
                    row.id,
                    row.title,
                    row.description,
                    row.clientName
                  );
                  // }
                }}
              >
                <FileCopyIcon
                  sx={{
                    fontSize: 22,
                    cursor: row.status !== "Active" ? "default" : "pointer",
                  }}
                  color={row.status !== "Active" ? "disabled" : "action"}
                />
              </div>
            </div>
          </TableCell>
          <TableCell component="th" scope="row">
            <p>{row.clientName}</p>
          </TableCell>
          <TableCell component="th" scope="row">
            <p
              className="track-jobs-job-title"
              onClick={() => {
                setIsDetailsMode(true);
                setSelectedJobId(row.id);
                setSelectedJobTitle(row.title);
                setSelectedJobDescription(row.description);
                setSelectedClientName(row.clientName);
                setCreatedDate(moment(row.createdOn).format("Do MMMM YYYY"));
                setInvitesSent(row.invitesSent);
                setIsClosed(row.status === "Closed");
              }}
            >
              {row.title}
            </p>
          </TableCell>
          <TableCell style={{ textAlign: "center" }}>{row.status}</TableCell>
          <TableCell style={{ textAlign: "center" }}>
            {moment(row.createdOn).format("Do MMM YYYY")}
          </TableCell>
          <TableCell style={{ textAlign: "center" }}>
            {row.invitesSent}
          </TableCell>
          <TableCell style={{ textAlign: "center" }}>{row.responded}</TableCell>
          <TableCell style={{ textAlign: "center" }}>
            {row.notResponded}
          </TableCell>
          <TableCell style={{ textAlign: "center" }}>
            {row.creditsSpent}
          </TableCell>
        </TableRow>
      </>
    );
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <p className="track-jobs-title title-border">Track Jobs</p>
      {cloning && (
        <Dialog title={`Cloning Job - ${selectedJobTitle}`}>
          <div style={{ display: "flex", gap: "0.5rem" }}>
            Job clone in progress....
          </div>
        </Dialog>
      )}
      {loading && <CircularProgress size={32} className="form-loading" />}
      {error && (
        <p
          className="form-error-message"
          style={{ marginLeft: "1rem", marginBottom: "1rem" }}
        >
          {error}
        </p>
      )}
      {!isEditMode && !isDetailsMode && !loading && (
        <>
          {jobs && jobs.length > 0 && (
            <div className="drafts-container">
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 540 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <b>Actions</b>
                        </TableCell>
                        <TableCell>
                          <b>Client</b>
                        </TableCell>
                        <TableCell>
                          <b>Title</b>
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          <b>Status</b>
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          <b>Created On</b>
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          <b>Invites Sent</b>
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          <b>JS Responded</b>
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          <b>JS Not Responded</b>
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          <b>Credits Spent</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {jobs
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row: any) => {
                          return <Row key={row.id} row={row} />;
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={jobs.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          )}
          {!loading && (!jobs || jobs.length === 0) && (
            <p className="drafts-no-drafts ">No jobs have been created yet</p>
          )}
        </>
      )}
      {isEditMode && (
        <>
          <Button
            className="track-jobs-all-jobs-button"
            label="Back to All Jobs"
            onClick={() => {
              setIsEditMode(false);
              fetchJobs();
            }}
            icon={<ArrowBackIcon fontSize="medium" />}
          />
          <div style={{ margin: "1.5rem 1.5rem 2rem 1.5rem" }}>
            <CreateJob
              id={selectedJobId}
              tilteToshow={selectedJobTitle}
              descriptionToShow={selectedJobDescription}
              clientNameToShow={selectedClientName}
              headerTitle={`Edit Job - ${selectedJobTitle}`}
              isActiveJob={invitesSent > 0 ? true : false}
              hideTitle
            />
          </div>
        </>
      )}
      {isDetailsMode && (
        <>
          <Button
            className="track-jobs-all-jobs-button"
            label="Back to All Jobs"
            onClick={() => {
              setIsDetailsMode(false);
              fetchJobs();
            }}
            icon={<ArrowBackIcon fontSize="medium" />}
          />
          <JobListingDetails
            id={selectedJobId}
            title={selectedJobTitle}
            description={selectedJobDescription}
            clientName={selectedClientName}
            createdDate={createdDate}
            invitesSent={invitesSent}
            onClose={() => {
              setIsDetailsMode(false);
              fetchJobs();
            }}
            closed={isClosed}
          />
        </>
      )}
    </>
  );
};

export default TrackJobs;
