import { useEffect, useState, useMemo } from "react";
import { CircularProgress } from "@mui/material";
import useRazorpay, { RazorpayOptions } from "react-razorpay";
import axios from "../../utils/axios";
import moment from "moment";
import Button from "../Button";
import Dialog from "../Dialog";
import Input from "../Input";
import Pagination from "../Pagination";
import { XANO_API_BASE_URL } from "../../utils/api-urls";
import "./styles.scss";

const Credits = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [credits, setCredits] = useState(0);
  const [spendHistory, setSpendHistory] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [creditsToPurchase, setCreditsToPurchase] = useState(100);
  const [showDialog, setShowDialog] = useState(false);
  const [creditsError, setCreditsError] = useState("");

  const numberOfTransactionsPerPage = 3;

  const pageData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * numberOfTransactionsPerPage;
    const lastPageIndex = firstPageIndex + numberOfTransactionsPerPage;
    return spendHistory.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, spendHistory]);

  const employerData = JSON.parse(
    localStorage.getItem("employerData")
      ? localStorage.getItem("employerData") || "{}"
      : "{}"
  );

  const Razorpay = useRazorpay();

  const handleAddCredits = () => {
    setCreditsToPurchase(100);
    setShowDialog(true);
  };

  const handlePayment = async () => {
    const response = await axios.post(
      "https://eh75h7mgrco436orngxjwjil3y0rkzgd.lambda-url.ap-south-1.on.aws/order",
      {
        amount: creditsToPurchase * 0.5,
      }
    );
    const orderId = response.data.id;
    const amount = response.data.amount;

    const options = {
      key: "rzp_test_E4pSCQEDBZIwqm",
      amount,
      currency: "INR",
      name: "Hiringhood",
      description: `Add ${creditsToPurchase} Credits`,
      image:
        "https://media.licdn.com/dms/image/D4E0BAQEPjuz5yQdGUQ/company-logo_200_200/0/1663844374296?e=2147483647&v=beta&t=7vGGsRjAGSTYNJ8vKvuJ0nKXhRbzM0MsE2hzAd9VXLY",
      order_id: orderId,
      handler: function (response: any) {
        setShowDialog(false);
        axios
          .post(`${XANO_API_BASE_URL}/emp_prefs/credits`, {
            employerId: employerData.id,
            transactionType: "Credit",
            transactionAmount: (amount * 2) / 100,
            updatedBalance: credits + (amount * 2) / 100,
            orderId: response["razorpay_order_id"],
            transactionId: response["razorpay_payment_id"],
          })
          .then(() => {
            fetchCredits();
          })
          .catch((err) => {});
      },
      prefill: {
        name: employerData.name,
        email: employerData.email,
      },
      notes: {
        paymentBy: employerData.email,
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response: any) {
      console.log(response.error);
      setError("There was an error with your payment");
    });

    try {
      rzp1.open();
    } catch (err) {
      console.log(err);
    }
  };

  const fetchCredits = async () => {
    try {
      setLoading(true);

      const creditsResponse: any = await axios.get(
        `${XANO_API_BASE_URL}/emp_prefs/getCredits?employerId=${employerData.id}`
      );

      setCredits(creditsResponse.data.credits);

      try {
        const spendHistoryResponse: any = await axios.get(
          `${XANO_API_BASE_URL}/emp_prefs/credit_history?employerId=${employerData.id}`
        );
        setSpendHistory([...spendHistoryResponse.data]);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
        setError(
          "An error occured while fetching the transaction history. Please try again later"
        );
      }
    } catch (err) {
      setLoading(false);
      setError(
        "An error occured while fetching the credits. Please try again later"
      );
    }
  };

  useEffect(() => {
    (async () => {})();
    fetchCredits();
  }, []);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  const handleCreditsChange = (value: any) => {
    if (value < 100) {
      setCreditsError("Minimum credits to purchase is 100");
    } else {
      setCreditsError("");
    }
    setCreditsToPurchase(value);
  };

  return (
    <>
      {loading && <CircularProgress size={32} className="form-loading" />}
      {!loading && error && (
        <p className="credits-text form-error-message">{error}</p>
      )}
      {showDialog && (
        <Dialog title="Add Credits" onClose={handleClose}>
          <div className="credits-add-credits-text">
            <Input
              label="Credits to Purchase"
              type="number"
              value={"" + creditsToPurchase}
              onChange={(event) => {
                handleCreditsChange(event?.target.value);
              }}
              onBlur={(event) => {
                handleCreditsChange(event?.target.value);
              }}
            />
            {creditsError && (
              <p className="credits-text form-error-message">{creditsError}</p>
            )}
            <Button
              label="Pay Now"
              className="credits-add-credits"
              onClick={handlePayment}
              disabled={
                !creditsToPurchase || parseInt("" + creditsToPurchase) < 100
              }
            />
          </div>
        </Dialog>
      )}
      {!loading && !error && (
        <div className="credits-container">
          <p className="credits-title title-border">Credits</p>
          <div className="credits-balance-container">
            <p className="credits-text">
              Your Credit Balance : <b>{credits} Credits </b>
            </p>
            {/* <Button label="Add Credits" onClick={handleAddCredits} /> */}
          </div>
          {spendHistory.length > 0 && (
            <>
              <p className="credits-text">Transaction History</p>
              {pageData.map((transaction) => {
                return (
                  <div
                    className="credits-transaction-card"
                    key={transaction.id}
                  >
                    <p className="credits-transaction-type">
                      {transaction.transactionType === "Debit"
                        ? "Spend"
                        : "Purchase"}{" "}
                    </p>
                    <p className="credits-text credits-transaction-date">
                      {moment(transaction.created_at).format("DD MMMM YYYY")}
                    </p>
                    {transaction.transactionType === "Debit" && (
                      <p className="credits-transaction-text">
                        Job Name: <b>{transaction["jobTitle"]}</b>
                      </p>
                    )}
                    {transaction.transactionType === "Debit" && (
                      <div className="credits-transaction-spend-container">
                        <div>
                          <p className="credits-transaction-spend-text">
                            Invites Sent
                          </p>
                          <p className="credits-transaction-spend-text credits-transaction-text">
                            <b>{transaction.transactionAmount / 20}</b>
                          </p>
                        </div>
                        <p className="credits-transaction-spend-icon">X</p>
                        <div>
                          <p className="credits-transaction-spend-text">
                            Credits per JS
                          </p>
                          <p className="credits-transaction-spend-text credits-transaction-text">
                            <b>20</b>
                          </p>
                        </div>
                        <p className="credits-transaction-spend-icon">=</p>
                        <div>
                          <p className="credits-transaction-spend-text">
                            Credits Spent
                          </p>
                          <p className="credits-transaction-spend-text credits-transaction-text">
                            <b>{transaction.transactionAmount}</b>
                          </p>
                        </div>
                      </div>
                    )}
                    {transaction.transactionType === "Credit" && (
                      <div className="credits-transaction-spend-container">
                        <div>
                          <p className="credits-transaction-spend-text">
                            Order Id
                          </p>
                          <p className="credits-transaction-spend-text credits-transaction-text">
                            <b>{transaction.orderId}</b>
                          </p>
                        </div>
                        <div>
                          <p className="credits-transaction-spend-text">
                            Transaction Id
                          </p>
                          <p className="credits-transaction-spend-text credits-transaction-text">
                            <b>{transaction.transactionId}</b>
                          </p>
                        </div>
                      </div>
                    )}
                    <div className="credits-transaction-balances-container">
                      <p className="credits-transaction-text">
                        Previous Balance :{" "}
                        <b>
                          {transaction.transactionType === "Debit"
                            ? transaction.UpdatedBalance +
                              transaction.transactionAmount
                            : transaction.UpdatedBalance -
                              transaction.transactionAmount}
                        </b>
                      </p>
                      <p className="credits-transaction-text">
                        New Balance : <b>{transaction.UpdatedBalance}</b>
                      </p>
                    </div>
                  </div>
                );
              })}
              <Pagination
                className="credits-pagination-container"
                currentPage={currentPage}
                totalCount={spendHistory.length}
                pageSize={numberOfTransactionsPerPage}
                onPageChange={onPageChange}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Credits;
