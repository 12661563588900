import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../utils/axios";
import OtpInput from "react-otp-input";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { validateEmail } from "../../utils/validators";
import { DASHBOARD } from "../../utils/routes";
import "./styles.scss";

const Login = (props: any) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const [isEmailvalid, setIsEmailValid] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [optLoading, setOtpLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleEmail = (email: string) => {
    setEmail(email);

    if (!email) {
      setError("Email cannot be empty");
      setIsEmailValid(false);
    } else if (!validateEmail(email)) {
      setError("Enter a vaild email address");
      setIsEmailValid(false);
    } else {
      setError("");
      setIsEmailValid(true);
    }
  };

  const handleSendOTP = async () => {
    setError("");
    setOtpLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_XANO_API_BASE_URL}/emp_prefs/generate_OTP?newEmployee=false`,
        {
          email,
          subject: "OTP for login",
          body: `Dear Recruiter, <br/><br/> Please use the OTP <b><OTP></b> for Login <br/> <br/> Regards,<br> Team Hiringhood`,
        }
      )
      .then(() => {
        setOtpLoading(false);
        setOtpSent(true);
      })
      .catch((err: any) => {
        setOtpLoading(false);
        const { response } = err;
        const { data } = response;
        if (data && data.message === "EMPLOYER_IS_NOT_REGISTERED") {
          setError(
            `The email address ${email} is not registered with us. Please register before proceeding to login`
          );
        } else {
          setError(
            "There was an error while trying to send an OTP to your email address. Please try again later"
          );
        }
      });
  };

  const handleSubmit = async () => {
    setError("");
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_XANO_API_BASE_URL}/emp_prefs/login_employer`,
        {
          email,
          OTP: otp,
        }
      )
      .then((response) => {
        setLoading(false);
        const { data } = response;
        const employerData = {
          id: data["employer_id"],
          company: data["Company_Name"],
          name: `${data["First_Name"]} ${data["Last_Name"]}`,
          email: data["email"],
        };
        localStorage.setItem("employerData", JSON.stringify(employerData));
        navigate(DASHBOARD);
      })
      .catch((err): any => {
        setLoading(false);
        const { response } = err;
        const { data } = response;
        if (data && data.message === "USER_NOT_REGISTERED") {
          setError(
            `The email address ${email} is not registered with us. Please register before proceeding to login`
          );
        } else {
          setError("There was an error during login. Please try again later");
        }
      });
  };

  return (
    <>
      <Input
        className="login-input"
        label="Email Address*"
        value={email}
        onChange={(event) => {
          handleEmail(event?.target.value);
        }}
        onBlur={(event) => {
          handleEmail(event?.target.value);
        }}
        disabled={otpSent}
      />
      {!otpSent && (
        <Button
          className="login-submit"
          label="Send OTP"
          disabled={!isEmailvalid}
          loading={optLoading}
          onClick={handleSendOTP}
        />
      )}
      {otpSent && (
        <>
          <p className="login-enter-otp">{`Enter the OTP received on your email address: ${email}`}</p>
          <div>
            <OtpInput
              shouldAutoFocus
              numInputs={4}
              inputStyle={"login-otp-input"}
              onChange={(otp: any) => {
                setOtp(otp);
              }}
              value={otp}
            />
          </div>
        </>
      )}
      {otpSent && (
        <Button
          className="login-submit"
          label="Login"
          disabled={!isEmailvalid || !(otp.length === 4)}
          loading={loading}
          onClick={handleSubmit}
        />
      )}
      {error && <p className="login-error">{error}</p>}
    </>
  );
};

export default Login;
