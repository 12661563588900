import { SET_CREDITS } from "../actions/credits";

const INITIAL_STATE = {
  user: {},
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_CREDITS:
      return {
        ...state,
        credits: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
