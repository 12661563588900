import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Form from "../Form";
import { companySelector, jobSelector } from "../../redux/selectors";
import { companyQuestions } from "../../utils/constants";
import { setCompany } from "../../redux/actions/company";

const Company = () => {
  const { company } = useSelector(companySelector);
  const { job } = useSelector(jobSelector);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const handleChange = (formData: any, isValid: boolean) => {
    dispatch(
      setCompany({
        ...company,
        formData: { ...company.formData, ...formData, jobId: job.id },
        isConfigured: isValid,
      })
    );
  };

  useEffect(() => {
    if (!company.isConfigured) {
      setError("You need to select atleast one preference from each section");
    } else {
      setError("");
    }
  }, [company.isConfigured]);

  return (
    <>
      <p className="form-title">Company</p>
      <Form
        id="company"
        questions={companyQuestions}
        handleChange={handleChange}
        formData={company.formData}
      />
      <p className="form-error-message">{error}</p>
    </>
  );
};

export default Company;
