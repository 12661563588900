import { useState, useEffect } from "react";
import axios from "../../utils/axios";
import EditIcon from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";
import MailIcon from "@mui/icons-material/Mail";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import { red } from "@mui/material/colors";
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import Button from "../Button";
import CreateJob from "../CreateJob";
import SendInvites from "../SendInvites";
import Slider from "../Slider";
import Dialog from "../Dialog";
import ViewResponses from "../ViewResponses";
import { useMediaQuery } from "../../utils/hooks";
import { XANO_API_BASE_URL } from "../../utils/api-urls";
import Input from "../Input";
import offerReleased from "../../assets/offer-released.svg";
import offerReleasedActive from "../../assets/offer-released-active.svg";
import "./styles.css";

const JobListingDetails = (props: any) => {
  const {
    id,
    title,
    description,
    clientName,
    createdDate,
    invitesSent,
    closed = false,
  } = props;

  const [jobSeekers, setJobSeekers] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [sendInviteMode, setSendInviteMode] = useState(false);
  const [emails, setEmails] = useState<any[]>([]);
  const [sendInviteLoading, setSendInviteLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [showViewResponses, setShowViewResponses] = useState(false);
  const [selectedJSId, setSelectedJSId] = useState("");
  const [selectedJSName, setSelectedJSName] = useState("");
  const [selectedJSEmail, setSelectedJSEmail] = useState("");
  const [isClosed, setIsClosed] = useState(closed);

  const [positiveScore, setPositiveScore] = useState(1);
  const [negativeScore, setNegativeScore] = useState(0);
  const [exponent, setExponent] = useState(1);
  const [showConfigurationDialog, setShowConfigurationDialog] = useState(false);
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [inviteError, setInviteError] = useState("");

  const employerData = JSON.parse(
    localStorage.getItem("employerData")
      ? localStorage.getItem("employerData") || "{}"
      : "{}"
  );

  const positiveMarks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 5,
      label: "5",
    },
    {
      value: 10,
      label: "10",
    },
    {
      value: 15,
      label: "15",
    },
  ];

  const negativeMarks = [
    {
      value: -15,
      label: "-15",
    },
    {
      value: -10,
      label: "-10",
    },
    {
      value: -5,
      label: "-5",
    },
    {
      value: 0,
      label: "0",
    },
  ];

  const fetchJS = async () => {
    try {
      setLoading(true);
      const probResponse: any = await axios.get(
        `${XANO_API_BASE_URL}/joining_probability/js_probabilities?jobId=${id}&exponent=${exponent}&positiveScore=${positiveScore}&negativeScore=${negativeScore}`
      );

      if (probResponse.data) {
        const invites = probResponse.data.shift();
        const jobSeekers = probResponse.data.map((jobSeeker: any) => {
          return {
            id: jobSeeker["User_ID"],
            email: jobSeeker["Email_ID"],
            name: jobSeeker["Name"],
            probability:
              jobSeeker["joining_probability"] &&
              jobSeeker["joining_probability"]["realistic"] &&
              (jobSeeker["joining_probability"]["realistic"]["probability"] ||
                jobSeeker["joining_probability"]["realistic"]["probability"] ===
                  0)
                ? Math.round(
                    jobSeeker["joining_probability"]["realistic"]["probability"]
                  ) + "%"
                : "NA",
            optimistic:
              jobSeeker["joining_probability"] &&
              jobSeeker["joining_probability"]["optimistic"] &&
              (jobSeeker["joining_probability"]["optimistic"]["probability"] ||
                jobSeeker["joining_probability"]["optimistic"][
                  "probability"
                ] === 0)
                ? Math.round(
                    jobSeeker["joining_probability"]["optimistic"][
                      "probability"
                    ]
                  ) + "%"
                : "NA",
            pessimistic:
              jobSeeker["joining_probability"] &&
              jobSeeker["joining_probability"]["pessimistic"] &&
              (jobSeeker["joining_probability"]["pessimistic"]["probability"] ||
                jobSeeker["joining_probability"]["pessimistic"][
                  "probability"
                ] === 0)
                ? Math.round(
                    jobSeeker["joining_probability"]["pessimistic"][
                      "probability"
                    ]
                  ) + "%"
                : "NA",
            offerReleased: jobSeeker["status"]["released"],
            offerAccepted: jobSeeker["status"]["accepted"],
          };
        });

        if (invites.length) {
          invites.forEach((invite: any) => {
            if (invite.invite === "pending") {
              jobSeekers.push({
                id: "",
                email: invite.email,
                name: "",
                probability: "NA",
              });
            }
          });
        }
        setJobSeekers([...jobSeekers]);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      fetchJS();
    })();
  }, []);

  const handleOfferReleaseAction = async (
    id: string,
    email: string,
    status: boolean
  ) => {
    setLoading(true);
    await axios.post(`${XANO_API_BASE_URL}/jp_emp_prefs/offer_released`, {
      job_id: id,
      offer: {
        email,
        released: status,
      },
    });
    await fetchJS();
  };

  const handleOfferAccepted = async (id: string, email: string) => {
    axios
      .post(`${XANO_API_BASE_URL}/jp_emp_prefs/offer_accepted`, {
        job_id: id,
        offer: {
          email,
          accepted: true,
        },
      })
      .then(() => {
        setSelectedJSEmail(email);
        setShowFeedbackDialog(true);
      });
  };

  const handleOfferRejected = async (id: string, email: string) => {
    axios
      .post(`${XANO_API_BASE_URL}/jp_emp_prefs/offer_accepted`, {
        job_id: id,
        offer: {
          email,
          accepted: false,
        },
      })
      .then(() => {
        setSelectedJSEmail(email);
        setShowFeedbackDialog(true);
      });
  };

  const handleFeedback = (id: string, feedback: string) => {
    axios
      .post(`${XANO_API_BASE_URL}/jp_emp_prefs/feedback`, {
        job_id: id,
        offer: {
          email: selectedJSEmail,
          feedback,
        },
      })
      .then(() => {
        setFeedback("");
        setShowFeedbackDialog(false);
        fetchJS();
      });
  };

  function Row(props: any) {
    const { row } = props;

    return (
      <>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>{row.name}</TableCell>
          <TableCell>{row.email}</TableCell>
          <TableCell style={{ textAlign: "center" }}>
            {row.probability !== "NA" ? "Responded" : "Not Responded"}
          </TableCell>
          <TableCell style={{ textAlign: "center" }}>
            {row.probability}
          </TableCell>
          <TableCell style={{ textAlign: "center" }}>
            <p
              style={
                row.probability !== "NA"
                  ? { color: "#0000CC", cursor: "pointer" }
                  : { color: "#d6d6d6" }
              }
              onClick={() => {
                if (row.probability !== "NA") {
                  setShowViewResponses(true);
                  setSelectedJSId(row.id);
                  setSelectedJSName(row.name);
                }
              }}
            >
              View Responses
            </p>
          </TableCell>
          <TableCell>
            <div
              title="Joined"
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
              }}
              onClick={() => {
                handleOfferAccepted(id, row.email);
              }}
            >
              Yes
              {row.offerAccepted === true ? (
                <ThumbUpAltIcon color="success" />
              ) : (
                <ThumbUpOffAltIcon />
              )}
            </div>
          </TableCell>
          <TableCell>
            <div
              title="Did Not Join"
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
              }}
              onClick={() => {
                handleOfferRejected(id, row.email);
              }}
            >
              No
              {row.offerAccepted === false ? (
                <ThumbDownAltIcon sx={{ color: red[500] }} />
              ) : (
                <ThumbDownOffAltIcon />
              )}
            </div>
          </TableCell>
          <TableCell style={{ textAlign: "center" }}>
            <div
              title="Offer Released"
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleOfferReleaseAction(id, row.email, !row.offerReleased);
              }}
            >
              {row.offerReleased === true ? (
                <img
                  src={offerReleasedActive}
                  style={{ width: "40px", height: "40px" }}
                />
              ) : (
                <img
                  src={offerReleased}
                  style={{ width: "40px", height: "40px" }}
                />
              )}
            </div>
          </TableCell>
        </TableRow>
      </>
    );
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseJob = async () => {
    setDeleteLoading(true);
    try {
      axios.post(`${XANO_API_BASE_URL}/emp_prefs/change_job_status`, {
        status: "Closed",
        job_id: id,
        employer_id: employerData.id,
      });
      setShowDialog(false);
      setDeleteLoading(false);
      setIsClosed(true);
      setSuccessMessage("Job has been successfully closed");
    } catch (err) {
      setDeleteLoading(false);
    }
  };

  const getInvites = (values: any) => {
    setEmails([...values]);
  };

  const handleSendInvite = async () => {
    setSendInviteLoading(true);
    try {
      const creditsResponse: any = await axios.get(
        `${XANO_API_BASE_URL}/emp_prefs/getCredits?employerId=${employerData.id}`
      );
      const credits = creditsResponse.data.credits;
      if (credits > 0) {
        axios
          .post(`${XANO_API_BASE_URL}/jp_emp_preferences/invites`, {
            job_id: id,
            invites: emails,
          })
          .then((response) => {
            axios
              .post(`${XANO_API_BASE_URL}/emp_prefs/credits`, {
                employerId: employerData.id,
                transactionType: "Debit",
                transactionAmount: emails.length * 20,
                updatedBalance: credits - emails.length * 20,
                jobTitle: title,
              })
              .then(() => {
                setSendInviteLoading(false);
                setSendInviteMode(false);
                fetchJS();
                setSuccessMessage("Invites have been successfully sent");
              })
              .catch((err) => {
                setSendInviteMode(false);
                setSendInviteLoading(false);
              });
          })
          .catch((err) => {
            setSendInviteMode(false);
            setSendInviteLoading(false);
          });
      } else {
        setInviteError(
          `Your credit balance of ${credits} credits is not sufficient for inviting ${emails.length} job seeker(s)`
        );
        setSendInviteLoading(false);
      }
    } catch (err) {
      setSendInviteMode(false);
      setSendInviteLoading(false);
    }
  };

  const handleViewResponseClose = () => {
    setShowViewResponses(false);
  };

  const largeDevice = useMediaQuery("(min-width:880px)");
  const mediumDevice = useMediaQuery("(min-width:672px)");
  const smallDevice = useMediaQuery("(min-width:500px)");

  const getWidth = () => {
    if (largeDevice) {
      return 400;
    } else if (mediumDevice) {
      return 300;
    } else if (smallDevice) {
      return 200;
    }

    return 200;
  };

  const handlePositiveScoreChange = (value: number) => {
    setPositiveScore(value);
  };

  const handleExponentChange = (value: number) => {
    setExponent(value);
  };

  const handleNegativeScoreChange = (value: number) => {
    setNegativeScore(value);
  };

  return (
    <div className="job-listing-details-container">
      <>
        {showConfigurationDialog && (
          <Dialog
            title={`Edit Configuartion`}
            onClose={() => {
              setShowConfigurationDialog(false);
            }}
          >
            <div className="view-response-configuration-container">
              <p>Exponential Weight</p>
              <Slider
                marks={positiveMarks}
                min={0}
                max={15}
                step={1}
                width={getWidth()}
                sliderValue={exponent}
                onSliderChange={handleExponentChange}
              />
              <p style={{ marginTop: "1rem" }}>Score for a Positive Match</p>
              <Slider
                marks={positiveMarks}
                min={0}
                max={15}
                step={1}
                width={getWidth()}
                sliderValue={positiveScore}
                onSliderChange={handlePositiveScoreChange}
              />
              <p style={{ marginTop: "1rem" }}>Penalty for a Negative Match</p>
              <Slider
                marks={negativeMarks}
                min={-15}
                max={0}
                step={1}
                width={getWidth()}
                sliderValue={negativeScore}
                onSliderChange={handleNegativeScoreChange}
              />
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <Button
                  className="view-response-configuration-button"
                  label="Default Settings"
                  onClick={() => {
                    setExponent(1);
                    setPositiveScore(1);
                    setNegativeScore(-1);
                    setShowConfigurationDialog(false);
                    fetchJS();
                  }}
                />
                <Button
                  className="view-response-configuration-button"
                  label="Submit"
                  onClick={() => {
                    setShowConfigurationDialog(false);
                    fetchJS();
                  }}
                />
              </div>
            </div>
          </Dialog>
        )}
        {showFeedbackDialog && (
          <Dialog
            title="Provide Feedback"
            onClose={() => {
              setShowFeedbackDialog(false);
              fetchJS();
            }}
          >
            <div className="feedback-input">
              <Input
                label="Feedback"
                value={feedback}
                onChange={(event: any) => {
                  setFeedback(event?.target.value);
                }}
                onBlur={(event: any) => {
                  setFeedback(event?.target.value);
                }}
                rows={5}
              />
            </div>
            <Button
              className="view-response-configuration-button"
              label="Submit"
              onClick={() => {
                handleFeedback(id, feedback);
              }}
              disabled={!feedback}
            />
          </Dialog>
        )}
      </>
      <div className="job-listing-details-title-container">
        <p className="job-listing-details-title">{`${title} ${
          isClosed ? "(Closed)" : ""
        }`}</p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <p>{`Created: ${createdDate}`}</p>
          {!isClosed && !loading && (
            <div className="job-listing-details-icons">
              {!editMode && (
                <div
                  title="Edit Job"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setEditMode(true);
                    setSuccessMessage("");
                  }}
                >
                  <EditIcon />
                </div>
              )}
              <div
                title="Close Job"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowDialog(true);
                  setSuccessMessage("");
                }}
              >
                <Close />
              </div>
              {invitesSent > 0 && (
                <div
                  title="Send Invites"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSendInviteMode(true);
                    setSuccessMessage("");
                    setInviteError("");
                  }}
                >
                  <MailIcon />
                </div>
              )}
              {/* <div
                title="Set Configuration"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowConfigurationDialog(true);
                }}
              >
                <SettingsIcon />
              </div> */}
            </div>
          )}
        </div>
      </div>
      {loading && <CircularProgress size={32} className="form-loading" />}
      {!loading && (
        <div>
          {showDialog && (
            <Dialog
              title={`Close Job - ${title}`}
              onClose={() => {
                setShowDialog(false);
              }}
            >
              <div className="drafts-delete-job-container">
                Are you sure you want to Close the job <b>{`${title}`} </b>?
                <div className="drafts-delete-job-buttons-container">
                  <Button
                    label="Cancel"
                    onClick={() => {
                      setShowDialog(false);
                    }}
                  />
                  <Button
                    label="Close"
                    variant="secondary"
                    onClick={handleCloseJob}
                    loading={deleteLoading}
                  />
                </div>
              </div>
            </Dialog>
          )}
          {sendInviteMode && (
            <Dialog
              className="send-invite-dialog"
              title="Send Invites"
              onClose={() => {
                setSendInviteMode(false);
              }}
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "600px !important",
                    maxWidth: "600px !important", // Set your width here
                  },
                },
              }}
            >
              <div className="drafts-delete-job-container">
                <SendInvites getInvitelist={getInvites} />
              </div>
              <div className="job-listing-details-send-invite-buttons-container">
                <Button
                  label="Cancel"
                  variant="outlined"
                  onClick={() => {
                    setSendInviteMode(false);
                  }}
                />
                <Button
                  label="Send Invites"
                  variant="primary"
                  onClick={handleSendInvite}
                  loading={sendInviteLoading}
                  disabled={emails.length === 0}
                />
              </div>
              <p className="form-error-message">{inviteError}</p>
            </Dialog>
          )}
          {successMessage && (
            <p className="job-listing-details-success-message">
              {successMessage}
            </p>
          )}
          {showViewResponses && (
            <ViewResponses
              jobId={id}
              jsId={selectedJSId}
              jsName={selectedJSName}
              onClose={handleViewResponseClose}
            />
          )}
          {jobSeekers &&
            jobSeekers.length > 0 &&
            !editMode &&
            !showViewResponses && (
              <>
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer sx={{ maxHeight: 540 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>Name</b>
                          </TableCell>
                          <TableCell>
                            <b>Email</b>
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            <b>Status</b>
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            <b>Joining Probability</b>
                            {/* <p>
                              <span title="Optimistic">
                                <b>O</b>
                              </span>
                              &nbsp; &nbsp; | &nbsp; &nbsp;
                              <span title="Realistic">
                                <b>R</b>
                              </span>
                              &nbsp; &nbsp; | &nbsp; &nbsp;
                              <span title="Pessimistic">
                                {" "}
                                <b>P</b>
                              </span>
                            </p> */}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            <b>View Responses</b>
                          </TableCell>
                          <TableCell
                            style={{ textAlign: "center" }}
                            colSpan={2}
                          >
                            <b>Joined?</b>
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            <b>Offer Released?</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {jobSeekers
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row: any) => {
                            return <Row key={row.id} row={row} />;
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50]}
                  component="div"
                  count={jobSeekers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />{" "}
              </>
            )}
          {(!jobSeekers || jobSeekers.length === 0) &&
            !editMode &&
            !showViewResponses && (
              <p className="job-listing-details-no-js">
                No invites have yet been sent for this job
              </p>
            )}
          {editMode && (
            <CreateJob
              id={id}
              tilteToshow={title}
              descriptionToShow={description}
              clientNameToShow={clientName}
              headerTitle="Edit"
              isActiveJob={invitesSent > 0 ? true : false}
              hideTitle
            />
          )}
        </div>
      )}
    </div>
  );
};

export default JobListingDetails;
