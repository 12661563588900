import { FC, useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CircularProgress from "@mui/material/CircularProgress";
import Multiselect from "multiselect-react-dropdown";
import { red } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import { useMediaQuery } from "../../utils/hooks";
import { locationSelector, jobSelector } from "../../redux/selectors";
import { setLocation } from "../../redux/actions/location";
import { LOCATION_TYPES } from "../../utils/site-data";
import office from "../../assets/company.png";
import hybrid from "../../assets/hybrid.png";
import remote from "../../assets/remote-work.png";
import onsite from "../../assets/work.png";
import { XANO_API_BASE_URL } from "../../utils/api-urls";
import "./styles.scss";

const Location: FC<any> = () => {
  const matches = useMediaQuery("(min-width:600px)");

  const dispatch = useDispatch();
  const { location } = useSelector(locationSelector);
  const { job } = useSelector(jobSelector);

  const locationOptions = [
    {
      id: "hybrid",
      name: "Hybrid",
      image: hybrid,
    },
    {
      id: "remote",
      name: "Remote",
      image: remote,
    },
    {
      id: "office",
      name: "Office",
      image: office,
    },
    {
      id: "onsite",
      name: "Onsite",
      image: onsite,
    },
  ];

  const getLocationOptionByName = useCallback(
    (name: string) => {
      const locationOption = locationOptions.filter((item) => {
        return item.name === name;
      })[0];

      return locationOption;
    },
    [locationOptions]
  );

  const [locations, setLocations] = useState<Object[]>([]);
  const [selectedCities, setSelectedCities] = useState<string[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [selectedLocationTypes, setSelectedLocationTypes] = useState<string[]>(
    []
  );

  const [locPreferredMode, setLocPreferredMode] = useState(
    location.formData ? location.formData.locPreferredMode : false
  );
  const [locPreferences, setLocPreferences] = useState(
    location.formData ? location.formData.locPreferences : false
  );
  const [locPreferredCities, setLocPreferredCities] = useState(
    location.formData ? location.formData.locPreferredCities : false
  );
  const [locPreferredCountries, setLocPreferredCountries] = useState(
    location.formData ? location.formData.locPreferredCountries : false
  );

  const [loading, setLoading] = useState(true);
  const [citiesLoading, setCitiesLoading] = useState(false);
  const [countryOptions, setCountryOptions] = useState<String[]>([]);
  const [cityOptions, setCityOptions] = useState<String[]>([]);

  useEffect(() => {
    setLocations(
      location.formData &&
        location.formData.preferences &&
        !(location.formData.preferences === ",,,")
        ? [
            ...location.formData.preferences
              .toString()
              .split(",")
              .map((preference: string) => {
                return getLocationOptionByName(preference);
              }),
          ]
        : locationOptions
    );
    setSelectedCities(
      location.formData && location.formData.preferredCities
        ? [...location.formData.preferredCities.split(",")]
        : []
    );
    setSelectedCountries(
      location.formData && location.formData.preferredCountries
        ? [...location.formData.preferredCountries.split(",")]
        : []
    );
    setSelectedLocationTypes(
      location.formData && location.formData.preferredMode
        ? [...location.formData.preferredMode.split(",")]
        : []
    );
    if (locPreferences) {
      setSelectedLocationTypes([]);
    }
    if (locPreferredCities) {
      setSelectedCities([]);
    }
    if (locPreferredCountries) {
      setSelectedCountries([]);
    }
  }, [
    getLocationOptionByName,
    locPreferences,
    locPreferredCities,
    locPreferredCountries,
    location,
    locationOptions,
  ]);

  useEffect(() => {
    if (isFormValid()) {
      dispatch(
        setLocation({
          ...location,
          formData: { ...location.formData, jobId: job.id },
          isConfigured: true,
        })
      );
    } else {
      dispatch(
        setLocation({
          ...location,
          formData: { ...location.formData, jobId: job.id },
          isConfigured: false,
        })
      );
    }
  }, [
    selectedLocationTypes,
    locPreferences,
    locPreferredCities,
    locPreferredCountries,
    selectedCities,
    selectedCountries,
  ]);

  const isFormValid = () => {
    const isQuestion2Valid = locPreferences || selectedLocationTypes.length > 0;
    const isQuestion3Valid =
      locPreferredCountries || selectedCountries.length > 0;
    const isQuestion4Valid =
      locPreferredCountries || locPreferredCities || selectedCities.length > 0;

    return isQuestion2Valid && isQuestion3Valid && isQuestion4Valid;
  };

  const getCountries = () => {
    setLoading(true);
    axios.get(`${XANO_API_BASE_URL}/getAllCountries`).then((response) => {
      const data: any = response.data;
      const options = data.map((item: any) => {
        return item.country;
      });
      setCountryOptions(options);
      setLoading(false);
    });
  };

  const getCitiesForCountries = (countries: any) => {
    setCitiesLoading(true);
    axios
      .get(
        `${XANO_API_BASE_URL}/getCitiesByCountries?countries=${countries.join(
          ","
        )}`
      )
      .then((response) => {
        const data: any = response.data;
        const options = data.map((item: any) => {
          return item.name;
        });
        setCityOptions(options);
        setCitiesLoading(false);
      });
  };

  useEffect(() => {
    (async () => {
      await getCountries();
    })();
  }, []);

  function handleOnDragEnd(result: any) {
    if (!result.destination) return;

    const items = Array.from(locations);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setLocations(items);

    dispatch(
      setLocation({
        ...location,
        formData: {
          ...location.formData,
          preferences: items
            .map((item: any) => {
              return item.name;
            })
            .join(","),
        },
      })
    );
  }

  const onCitySelected = (cities: string[], selectedCity: string) => {
    setSelectedCities([...cities]);
    dispatch(
      setLocation({
        ...location,
        formData: {
          ...location.formData,
          preferredCities: cities.join(","),
        },
      })
    );
  };

  const onCountrySelected = (countries: string[], selectedCountry: string) => {
    setSelectedCountries([...countries]);
    dispatch(
      setLocation({
        ...location,
        formData: {
          ...location.formData,
          preferredCountries: countries.join(","),
        },
      })
    );
  };

  const onLocationTypeSelected = (
    locationTypes: string[],
    selectedLocation: string
  ) => {
    setSelectedLocationTypes([...locationTypes]);
    dispatch(
      setLocation({
        ...location,
        isConfigured: true,
        formData: {
          ...location.formData,
          preferredMode: locationTypes.toString(),
        },
      })
    );
  };

  return (
    <>
      {loading && <CircularProgress size={32} className="form-loading" />}
      {!loading && (
        <div className="location-container">
          <>
            <p className="form-title">Location</p>
            <div className="form-question-title-container">
              <p
                className={`form-question ${
                  locPreferredMode ? "form-question-disabled" : ""
                }`}
              >
                Which mode of location can you provide for this job? Give an
                order of preference
              </p>
              <div className="form-check">
                <input
                  type="checkbox"
                  checked={locPreferredMode}
                  className="form-check-input"
                  onClick={() => {
                    dispatch(
                      setLocation({
                        ...location,
                        formData: {
                          ...location.formData,
                          locPreferredMode: !locPreferredMode,
                        },
                      })
                    );
                    setLocPreferredMode(!locPreferredMode);
                  }}
                />
                <p
                  className="form-check-label"
                  style={{ color: "#ff0000", fontWeight: "bold" }}
                >
                  Disable
                </p>
              </div>
            </div>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable
                droppableId="preferences"
                direction={matches ? "horizontal" : "vertical"}
              >
                {(provided) => (
                  <div
                    className="location-items-container"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {locations.map((item: any, index: number) => {
                      return (
                        <div key={item.id}>
                          <p className="location-index">{index + 1}</p>
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                            isDragDisabled={locPreferredMode}
                          >
                            {(provided) => (
                              <div
                                className="location-item"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className="location-icon-container">
                                  <img
                                    className="location-icon"
                                    src={item.image}
                                    alt={item.name}
                                  ></img>
                                </div>
                                <p className="location-item-text">
                                  {item.name}
                                </p>
                              </div>
                            )}
                          </Draggable>
                        </div>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <div className="form-question-title-container location-question-title-container">
              <p
                className={`form-question ${
                  locPreferences ? "form-question-disabled" : ""
                }`}
              >
                In which location can you provide this job?
              </p>
              <div className="form-check">
                <input
                  type="checkbox"
                  checked={locPreferences}
                  className="form-check-input"
                  onChange={(event) => {
                    dispatch(
                      setLocation({
                        ...location,
                        formData: {
                          ...location.formData,
                          locPreferences: !locPreferences,
                        },
                        isConfigured: event?.target?.checked
                          ? true
                          : selectedLocationTypes.length > 0
                          ? true
                          : false,
                      })
                    );
                    setLocPreferences(!locPreferences);
                  }}
                />
                <p
                  className="form-check-label"
                  style={{ color: "#ff0000", fontWeight: "bold" }}
                >
                  Disable
                </p>
              </div>
            </div>
            <Multiselect
              isObject={false}
              onSelect={(locationTypes, selectedLocationType) => {
                onLocationTypeSelected(locationTypes, selectedLocationType);
              }}
              onRemove={(locationTypes, removedLocationType) => {
                setSelectedLocationTypes([...locationTypes]);
                dispatch(
                  setLocation({
                    ...location,
                    isConfigured: locationTypes.length > 0 ? true : false,
                    formData: {
                      ...location.formData,
                      preferredMode: locationTypes.toString(),
                    },
                  })
                );
              }}
              selectedValues={selectedLocationTypes}
              options={LOCATION_TYPES}
              placeholder={
                selectedLocationTypes.length > 0 ? "" : "Choose a location type"
              }
              showCheckbox
              showArrow
              customCloseIcon={
                <CloseIcon
                  sx={{
                    color: red[500],
                    cursor: "pointer",
                    marginLeft: "0.5rem",
                  }}
                />
              }
              style={{
                chips: {
                  background: "rgba(155, 169, 232, 0.4)",
                  padding: "0.5rem 1rem",
                  color: "#000000",
                  fontSize: "1rem",
                  fontWeight: "bold",
                },
              }}
              disable={locPreferences}
            />
            <div className="form-question-title-container location-question-title-container">
              <p
                className={`form-question ${
                  locPreferredCountries ? "form-question-disabled" : ""
                }`}
              >
                In which countries can you provide this job?
              </p>
              <div className="form-check">
                <input
                  type="checkbox"
                  checked={locPreferredCountries}
                  className="form-check-input"
                  onClick={() => {
                    dispatch(
                      setLocation({
                        ...location,
                        formData: {
                          ...location.formData,
                          locPreferredCountries: !locPreferredCountries,
                          locPreferredCities: locPreferredCountries
                            ? true
                            : false,
                          preferredCountries: "",
                          preferredCities: "",
                        },
                      })
                    );
                    setLocPreferredCountries(!locPreferredCountries);
                  }}
                />
                <p
                  className="form-check-label"
                  style={{ color: "#ff0000", fontWeight: "bold" }}
                >
                  Disable
                </p>
              </div>
            </div>
            <Multiselect
              isObject={false}
              onSelect={(countries, selectedCountry) => {
                onCountrySelected(countries, selectedCountry);
                getCitiesForCountries(countries);
              }}
              onRemove={(countries, removedCountry) => {
                setSelectedCountries([...countries]);
                dispatch(
                  setLocation({
                    ...location,
                    formData: {
                      ...location.formData,
                      preferredCountries: countries.toString(),
                      preferredCities: "",
                    },
                  })
                );
                getCitiesForCountries(countries);
              }}
              selectedValues={selectedCountries}
              options={countryOptions}
              loading={loading}
              placeholder={selectedCountries.length > 0 ? "" : "Choose Country"}
              showCheckbox
              showArrow
              customCloseIcon={
                <CloseIcon
                  sx={{
                    color: red[500],
                    cursor: "pointer",
                    marginLeft: "0.5rem",
                  }}
                />
              }
              style={{
                chips: {
                  background: "rgba(155, 169, 232, 0.4)",
                  padding: "0.5rem 1rem",
                  color: "#000000",
                  fontSize: "1rem",
                  fontWeight: "bold",
                },
              }}
              disable={locPreferredCountries}
            />
            <div className="form-question-title-container location-question-title-container">
              <p
                className={`form-question ${
                  locPreferredCities || locPreferredCountries
                    ? "form-question-disabled"
                    : ""
                }`}
              >
                In which cities can you provide this job?
              </p>
              <div className="form-check">
                <input
                  type="checkbox"
                  checked={locPreferredCities || locPreferredCountries}
                  onClick={() => {
                    dispatch(
                      setLocation({
                        ...location,
                        formData: {
                          ...location.formData,
                          locPreferredCities: !locPreferredCities,
                          preferredCities: "",
                        },
                      })
                    );
                    setLocPreferredCities(!locPreferredCities);
                  }}
                  disabled={locPreferredCountries}
                />
                <p
                  className={`location-form-check-label ${
                    locPreferredCountries
                      ? "location-form-check-label-disabled"
                      : ""
                  }`}
                  style={{ fontWeight: "bold" }}
                >
                  Disable
                </p>
              </div>
            </div>
            <Multiselect
              isObject={false}
              onSelect={(cities, selectedCity) => {
                onCitySelected(cities, selectedCity);
              }}
              onRemove={(cities, removedCity) => {
                setSelectedCities([...cities]);
                dispatch(
                  setLocation({
                    ...location,
                    formData: {
                      ...location.formData,
                      preferredCities: cities.toString(),
                    },
                  })
                );
              }}
              selectedValues={selectedCities}
              options={cityOptions}
              loading={citiesLoading}
              placeholder={selectedCities.length > 0 ? "" : "Choose City"}
              showCheckbox
              showArrow
              customCloseIcon={
                <CloseIcon
                  sx={{
                    color: red[500],
                    cursor: "pointer",
                    marginLeft: "0.5rem",
                  }}
                />
              }
              style={{
                chips: {
                  background: "rgba(155, 169, 232, 0.4)",
                  padding: "0.5rem 1rem",
                  color: "#000000",
                  fontSize: "1rem",
                  fontWeight: "bold",
                },
              }}
              disable={
                locPreferredCities ||
                locPreferredCountries ||
                selectedCountries.length === 0
              }
            />
            {!isFormValid() && (
              <p className="form-error-message">
                You need to select at least one preference for each section
              </p>
            )}
          </>
        </div>
      )}
    </>
  );
};

export default Location;
