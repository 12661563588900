import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "../../utils/axios";
import moment from "moment";
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "../Button";
import CreateJob from "../CreateJob";
import Dialog from "../Dialog";
import { setJob } from "../../redux/actions/job";
import { setCompany } from "../../redux/actions/company";
import { setGrowth } from "../../redux/actions/growth";
import { setLocation } from "../../redux/actions/location";
import { setPerks } from "../../redux/actions/perks";
import { setRole } from "../../redux/actions/role";
import { setSalary } from "../../redux/actions/salary";
import { setWork } from "../../redux/actions/work";
import { setCredits } from "../../redux/actions/credits";
import { XANO_API_BASE_URL } from "../../utils/api-urls";
import "./styles.scss";

const Drafts = () => {
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState<string[]>([]);

  const [showDialog, setShowDialog] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState("");
  const [selectedJobTitle, setSelectedJobTitle] = useState("");
  const [selectedJobDescription, setSelectedJobDescription] = useState("");
  const [selectedClientName, setSelectedClientName] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const employerData = JSON.parse(
    localStorage.getItem("employerData")
      ? localStorage.getItem("employerData") || "{}"
      : "{}"
  );

  const dispatch = useDispatch();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchDraftJobs = async () => {
    try {
      setLoading(true);
      axios
        .get(
          `${XANO_API_BASE_URL}/jp_job_seeker_preferences/all_job_details?employerId=${employerData.id}`
        )
        .then((response) => {
          const { data } = response;
          setRows([
            ...data.filter((jd: any) => {
              return jd.status === "Draft";
            }),
          ]);
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
    }
  };

  const resetData = () => {
    dispatch(setJob({}));
    dispatch(setCompany({}));
    dispatch(setGrowth({}));
    dispatch(setLocation({}));
    dispatch(setPerks({}));
    dispatch(setRole({}));
    dispatch(setSalary({}));
    dispatch(setWork({}));
    dispatch(setCredits({}));
  };

  useEffect(() => {
    (async () => {
      fetchDraftJobs();
    })();
  }, []);

  const handleDelete = (id: string, title: string) => {
    setSelectedJobId(id);
    setSelectedJobTitle(title);
    setShowDialog(true);
  };

  const handleEdit = (
    id: string,
    title: string,
    description: string,
    clientName: string
  ) => {
    resetData();
    setSelectedJobId(id);
    setSelectedJobTitle(title);
    setSelectedJobDescription(description);
    setSelectedClientName(clientName);
    dispatch(
      setJob({
        id,
        jobTitle: title,
        jobDescription: description,
        clientName,
      })
    );
    setIsEditMode(true);
  };

  const handleJobDelete = async () => {
    setDeleteLoading(true);
    try {
      axios.delete(`${XANO_API_BASE_URL}/emp_prefs/delete_job`, {
        data: { jobId: selectedJobId },
      });
      setShowDialog(false);
      await fetchDraftJobs();
      setDeleteLoading(false);
    } catch (err) {
      setDeleteLoading(false);
    }
  };

  function Row(props: any) {
    const { row } = props;

    return (
      <>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <div className="drafts-action-buttons-container">
              <div
                style={{ cursor: "pointer", marginRight: "0.5rem" }}
                onClick={() => {
                  handleEdit(
                    row.jobId,
                    row["job_title"],
                    row.Description,
                    row["Client_Name"]
                  );
                }}
              >
                <EditIcon sx={{ fontSize: 22 }} color="action" />
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleDelete(row.jobId, row["job_title"]);
                }}
              >
                <DeleteIcon sx={{ fontSize: 22 }} color="action" />
              </div>
            </div>
          </TableCell>
          <TableCell component="th" scope="row">
            <b>{row["job_title"]}</b>
          </TableCell>
          <TableCell style={{ textAlign: "center" }}>
            {moment(row["created_at"]).format("Do MMM YYYY")}
          </TableCell>
        </TableRow>
      </>
    );
  }
  return (
    <div className="drafts-wrapper">
      {!isEditMode && (
        <>
          {showDialog && (
            <Dialog
              title={`Delete Job - ${selectedJobTitle}`}
              onClose={handleClose}
            >
              <div className="drafts-delete-job-container">
                Are you sure you want to delete the job{" "}
                <b>{`${selectedJobTitle}`} </b>?
                <div className="drafts-delete-job-buttons-container">
                  <Button label="Cancel" onClick={handleClose} />
                  <Button
                    label="Delete"
                    variant="secondary"
                    onClick={handleJobDelete}
                    loading={deleteLoading}
                  />
                </div>
              </div>
            </Dialog>
          )}
          <p className="drafts-title title-border">Drafts</p>
          {loading && <CircularProgress size={32} className="form-loading" />}
          {!loading && rows.length === 0 && (
            <p className="drafts-no-drafts">
              You don't have any jobs in draft state
            </p>
          )}
          {!loading && rows.length > 0 && (
            <div className="drafts-container">
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 540 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ maxWidth: 80 }}>Actions</TableCell>
                        <TableCell style={{ minWidth: 150 }}>
                          Job Title
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          Created On
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row: any) => {
                          return <Row key={row.id} row={row} />;
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          )}
        </>
      )}
      {isEditMode && (
        <>
          <Button
            className="drafts-all-drafts-button"
            variant="outlined"
            label="All Drafts"
            onClick={() => {
              setIsEditMode(false);
              fetchDraftJobs();
            }}
          />
          <div className="drafts-edit-job-container">
            <CreateJob
              id={selectedJobId}
              tilteToshow={selectedJobTitle}
              descriptionToShow={selectedJobDescription}
              clientNameToShow={selectedClientName}
              headerTitle={`Edit Job - ${selectedJobTitle}`}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Drafts;
