import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Multiselect from "multiselect-react-dropdown";
import { red } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import Form from "../Form";
import { workSelector, jobSelector } from "../../redux/selectors";
import { setWork } from "../../redux/actions/work";
import { workQuestions } from "../../utils/constants";
import { XANO_API_BASE_URL } from "../../utils/api-urls";
import "./styles.scss";

const Work = () => {
  const [error, setError] = useState("");
  const { work } = useSelector(workSelector);
  const [industries, setIndustries] = useState<string[]>(
    work.formData && work.formData.industry ? [work.formData.industry] : []
  );
  const [industryDisabled, setIndustryDisabled] = useState(
    work.formData && work.formData.industryDisable ? true : false
  );
  const [isFormValid, setIsFormValid] = useState(work.isConfigured);
  const dispatch = useDispatch();
  const { job } = useSelector(jobSelector);

  const [loading, setLoading] = useState(true);
  const [industryOptions, setIndustryOptions] = useState<String[]>([]);

  const handleChange = (formData: any, isValid: boolean) => {
    setIsFormValid(isValid);
    dispatch(
      setWork({
        ...work,
        formData: { ...work.formData, ...formData, jobId: job.id },
        isConfigured:
          isValid && (industries.length > 0 || work.formData.industryDisable),
      })
    );
  };

  useEffect(() => {
    if (!work.isConfigured) {
      setError("You need to select atleast one preference from each section");
    } else {
      setError("");
    }
  }, [work.isConfigured]);

  const getIndustries = () => {
    setLoading(true);
    axios.get(`${XANO_API_BASE_URL}/industries`).then((response) => {
      const data: any = response.data;
      const options = data.map((item: any) => {
        return item.industryName;
      });
      setIndustryOptions(options);
      setLoading(false);
    });
  };

  useEffect(() => {
    (async () => {
      await getIndustries();
    })();
  }, []);

  const handleDisableIndustry = (target: any) => {
    setIndustryDisabled(target.checked);
    const formData = work.formData;
    if (target.checked) {
      setIndustries([]);
      formData.industryDisable = true;
    } else {
      formData.industryDisable = false;
    }
    dispatch(
      setWork({
        ...work,
        formData: {
          ...formData,
          industry: "",
        },
        isConfigured: isFormValid && !industryDisabled,
      })
    );
  };

  return (
    <>
      {loading && <CircularProgress size={32} className="form-loading" />}
      {!loading && (
        <>
          <p className="form-title">Work</p>
          <Form
            id="work"
            questions={workQuestions}
            handleChange={handleChange}
            formData={work.formData}
          />
          <div className="form-question-title-container">
            <p
              className={`form-question ${
                industryDisabled ? "form-question-disabled" : ""
              }`}
            >
              In which countries can you provide this job?
            </p>
            <div className="form-check">
              <input
                type="checkbox"
                checked={industryDisabled}
                className="form-check-input"
                onClick={(e) => {
                  handleDisableIndustry(e.target);
                }}
              />
              <p
                className="form-check-label"
                style={{ color: "#ff0000", fontWeight: "bold" }}
              >
                Disable
              </p>
            </div>
          </div>
          <div className="work-industry">
            <Multiselect
              className="work-industry-dropdown"
              isObject={false}
              disable={industryDisabled}
              onSelect={(industries, selectedIndustry) => {
                setIndustries(industries);
                dispatch(
                  setWork({
                    ...work,
                    formData: {
                      ...work.formData,
                      industry: industries.toString(),
                    },
                    isConfigured: isFormValid && industries.length > 0,
                  })
                );
              }}
              selectedValues={industries}
              options={industryOptions}
              placeholder={industries.length > 0 ? "" : "Choose Industry"}
              showCheckbox
              showArrow
              customCloseIcon={
                <CloseIcon
                  sx={{
                    color: red[500],
                    cursor: "pointer",
                    marginLeft: "0.5rem",
                  }}
                />
              }
              style={{
                chips: {
                  background: "rgba(155, 169, 232, 0.4)",
                  padding: "0.5rem 1rem",
                  color: "#000000",
                  fontSize: "1rem",
                  fontWeight: "bold",
                  wordWrap: "break-word",
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                },
              }}
              singleSelect
            />
          </div>
          <p className="form-error-message">{error}</p>
        </>
      )}
    </>
  );
};

export default Work;
