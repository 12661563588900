export const preferncesSelector = (state: any) => state.preferences;
export const userSelector = (state: any) => state.user;
export const growthSelector = (state: any) => state.growth;
export const roleSelector = (state: any) => state.role;
export const salarySelector = (state: any) => state.salary;
export const perksSelector = (state: any) => state.perks;
export const workSelector = (state: any) => state.work;
export const locationSelector = (state: any) => state.location;
export const companySelector = (state: any) => state.company;
export const jobSelector = (state: any) => state.job;
export const creditsSelector = (state: any) => state.credits;
