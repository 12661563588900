import perks from "../assets/perks.png";
import growth from "../assets/growth.png";
import location from "../assets/location.png";
import company from "../assets/company.png";
import salary from "../assets/salary.png";
import role from "../assets/role.png";
import work from "../assets/work.png";

export const JOB_SEEKER_PREFERENCES = [
  {
    id: "company",
    name: "Company",
    image: company,
  },
  {
    id: "growth",
    name: "Growth",
    image: growth,
  },
  {
    id: "location",
    name: "Location",
    image: location,
  },
  {
    id: "perks",
    name: "Perks",
    image: perks,
  },
  {
    id: "role",
    name: "Role",
    image: role,
  },
  {
    id: "salary",
    name: "Salary",
    image: salary,
  },
  {
    id: "work",
    name: "Work",
    image: work,
  },
];

export const LOCATION_TYPES = [
  "Metro",
  "Small Town",
  "Tier 1 City",
  "Tier 2 City",
];
