import CloseIcon from "@mui/icons-material/Close";
import { red } from "@mui/material/colors";
import "./styles.scss";

const Tag = (props: any) => {
  const { text, onClose } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <div className="tag">
      {text}
      <CloseIcon
        onClick={handleClose}
        sx={{
          color: red[500],
          cursor: "pointer",
        }}
      />
    </div>
  );
};

export default Tag;
