import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import axios from "../../utils/axios";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Button from "../../components/Button";
import JobDetails from "../JobDetails";
import Company from "../Company";
import Growth from "../Growth";
import Location from "../Location";
import Perks from "../Perks";
import Role from "../Role";
import Salary from "../Salary";
import Work from "../Work";
import SendInvites from "../SendInvites";
import Dialog from "../Dialog";
import { setJob } from "../../redux/actions/job";
import { setCompany } from "../../redux/actions/company";
import { setGrowth } from "../../redux/actions/growth";
import { setLocation } from "../../redux/actions/location";
import { setPerks } from "../../redux/actions/perks";
import { setRole } from "../../redux/actions/role";
import { setSalary } from "../../redux/actions/salary";
import { setWork } from "../../redux/actions/work";
import { setCredits } from "../../redux/actions/credits";
import {
  jobSelector,
  companySelector,
  growthSelector,
  locationSelector,
  perksSelector,
  roleSelector,
  salarySelector,
  workSelector,
  creditsSelector,
} from "../../redux/selectors";
import { XANO_API_BASE_URL } from "../../utils/api-urls";
import { DASHBOARD } from "../../utils/routes";
import emailImage from "../../assets/email.png";
import "./styles.scss";

const steps = ["Job Details", "JD/Job Specific Preferences", "Send Invites"];

const CreateJob = (props: any) => {
  const {
    id = "",
    tilteToshow = "",
    descriptionToShow = "",
    clientNameToShow = "",
    headerTitle,
    isActiveJob = false,
    hideTitle = false,
  } = props;
  const dispatch = useDispatch();
  const { job } = useSelector(jobSelector);
  const { company } = useSelector(companySelector);
  const { growth } = useSelector(growthSelector);
  const { location } = useSelector(locationSelector);
  const { perks } = useSelector(perksSelector);
  const { role } = useSelector(roleSelector);
  const { salary } = useSelector(salarySelector);
  const { work } = useSelector(workSelector);
  const { credits } = useSelector(creditsSelector);
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);
  const [title, setTitle] = useState(tilteToshow ? tilteToshow : "New Job");
  const [error, setError] = useState("");

  const [jobTitle, setJobTitle] = useState(tilteToshow);
  const [jobDescription, setJobDescription] = useState(descriptionToShow);
  const [clientName, setClientName] = useState(clientNameToShow);

  const [previousButtonDisabled, setPreviousButtonDisabled] = useState(true);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [activePreferenceTab, setActivePreferenceTab] = useState(0);
  const [inviteList, setInviteList] = useState<string[]>([]);
  const [showDialog, setShowDialog] = useState(false);
  const [showCompleteDialog, setShowCompleteDialog] = useState(false);
  const [jobDataLoading, setJobDataLoading] = useState(false);

  const employerData = JSON.parse(
    localStorage.getItem("employerData")
      ? localStorage.getItem("employerData") || "{}"
      : "{}"
  );

  const handleJobDetailsChange = (
    title: string,
    description: string,
    clientName: string
  ) => {
    setJobTitle(title);
    setJobDescription(description);
    setClientName(clientName);
  };

  const getInvites = (inviteList: any) => {
    setInviteList(inviteList);
    setError("");
  };

  useEffect(() => {
    if (id) {
      dispatch(
        setJob({
          id,
          jobTitle: tilteToshow,
          jobDescription: descriptionToShow,
          clientName: clientNameToShow,
        })
      );
    } else {
      dispatch(setJob({}));
    }
  }, [clientNameToShow, descriptionToShow, dispatch, id, tilteToshow]);

  useEffect(() => {
    dispatch(setCompany({ formData: {} }));
    dispatch(setGrowth({ formData: {} }));
    dispatch(setLocation({ formData: {} }));
    dispatch(setPerks({ formData: {} }));
    dispatch(setRole({ formData: {} }));
    dispatch(setSalary({ formData: {} }));
    dispatch(setWork({ formData: {} }));
    dispatch(setCredits({ formData: {} }));
    if (id) {
      setJobDataLoading(true);
      axios
        .get(`${XANO_API_BASE_URL}/emp_prefs/get_prefs_by_job_id?jobId=${id}`)
        .then((response) => {
          const data: any = response.data;
          dispatch(
            setCompany({
              ...company,
              formData: {
                ...data.company,
                jobId: id,
                companySize: data.disabled_questions["companySize"],
                companyKind: data.disabled_questions["companyKind"],
                companyType: data.disabled_questions["companyType"],
              },
            })
          );
          dispatch(
            setGrowth({
              ...growth,
              formData: {
                ...data.growth,
                jobId: id,
                growthKind: data.disabled_questions["growthKind"],
                growthSupport: data.disabled_questions["growthSupport"],
                growthHike: data.disabled_questions["growthHike"],
              },
            })
          );
          dispatch(
            setPerks({
              ...perks,
              formData: {
                ...data.perks,
                jobId: id,
                perksKind: data.disabled_questions["perksKind"],
                perksAdditionalFacilities:
                  data.disabled_questions["perksAdditionalFacilities"],
              },
            })
          );
          dispatch(
            setLocation({
              ...location,
              formData: {
                ...data.location,
                jobId: id,
                locPreferences: data.disabled_questions["locPreferences"],
                locPreferredCities:
                  data.disabled_questions["locPreferredCities"],
                locPreferredMode: data.disabled_questions["locPreferredMode"],
                locPreferredCountries:
                  data.disabled_questions["locPreferredCountries"],
              },
            })
          );
          dispatch(
            setRole({
              ...role,
              formData: {
                ...data.role,
                jobId: id,
                preferredRole: data.disabled_questions["preferredRole"],
                preferredEmployment:
                  data.disabled_questions["preferredEmployment"],
                preferredDesignation:
                  data.disabled_questions["preferredDesignation"],
              },
            })
          );
          dispatch(
            setSalary({
              ...salary,
              formData: {
                ...data.salary,
                jobId: id,
                salaryRangeDisabled: data.disabled_questions["salaryRange"],
                salaryHikeDisabled: data.disabled_questions["salaryHike"],
                salaryComponents: data.disabled_questions["salaryComponents"],
              },
            })
          );
          dispatch(
            setWork({
              ...work,
              formData: {
                ...data.work,
                jobId: id,
                workTime: data.disabled_questions["workTime"],
                workShift: data.disabled_questions["workShift"],
                workEnvironment: data.disabled_questions["workEnvironment"],
                workKind: data.disabled_questions["workKind"],
                industryDisable: data.disabled_questions["Industry"],
              },
            })
          );
          setJobDataLoading(false);
        });
    }
  }, [id]);

  useEffect(() => {
    if (isActiveJob && activePreferenceTab !== 6) {
      setActiveStep(1);
    }
    if (isActiveJob && activeStep === 1 && activePreferenceTab === 0) {
      setPreviousButtonDisabled(true);
    } else if (activeStep === 0 || loading) {
      setPreviousButtonDisabled(true);
    } else {
      setPreviousButtonDisabled(false);
    }
    if (activeStep === 0) {
      if (jobTitle && jobDescription && clientName) {
        setNextButtonDisabled(false);
      } else {
        setNextButtonDisabled(true);
      }
    }

    if (activeStep === 1) {
      if (activePreferenceTab === 0) {
        if (company.isConfigured) {
          setNextButtonDisabled(false);
        } else {
          setNextButtonDisabled(true);
        }
      }

      if (activePreferenceTab === 1) {
        if (growth.isConfigured) {
          setNextButtonDisabled(false);
        } else {
          setNextButtonDisabled(true);
        }
      }

      if (activePreferenceTab === 2) {
        if (location.isConfigured) {
          setNextButtonDisabled(false);
        } else {
          setNextButtonDisabled(true);
        }
      }

      if (activePreferenceTab === 3) {
        if (perks.isConfigured) {
          setNextButtonDisabled(false);
        } else {
          setNextButtonDisabled(true);
        }
      }

      if (activePreferenceTab === 4) {
        if (role.isConfigured) {
          setNextButtonDisabled(false);
        } else {
          setNextButtonDisabled(true);
        }
      }

      if (activePreferenceTab === 5) {
        if (salary.isConfigured) {
          setNextButtonDisabled(false);
        } else {
          setNextButtonDisabled(true);
        }
      }

      if (activePreferenceTab === 6) {
        if (work.isConfigured) {
          setNextButtonDisabled(false);
        } else {
          setNextButtonDisabled(true);
        }
      }
    }
    if (activeStep === 2) {
      if (credits && inviteList.length > 0) {
        if (
          inviteList.length <= 0 ||
          inviteList.length * 20 > credits.credits
        ) {
          setNextButtonDisabled(true);
        } else {
          setNextButtonDisabled(false);
        }
        if (inviteList.length * 20 > credits.credits) {
          setError(
            `Your credit balance of ${credits.credits} credits is not sufficient for inviting ${inviteList.length} job seeker(s)`
          );
        }
      } else {
        setNextButtonDisabled(true);
      }
    }
  }, [
    activePreferenceTab,
    activeStep,
    clientName,
    jobDescription,
    jobTitle,
    company.isConfigured,
    growth.isConfigured,
    location.isConfigured,
    perks.isConfigured,
    role.isConfigured,
    salary.isConfigured,
    work.isConfigured,
    inviteList.length,
    credits,
    dispatch,
    id,
    job.id,
    tilteToshow,
    descriptionToShow,
    isActiveJob,
    loading,
  ]);

  useEffect(() => {
    if (activeStep === 2) {
      (async () => {
        try {
          setLoading(true);
          const creditsResponse: any = await axios.get(
            `${XANO_API_BASE_URL}/emp_prefs/getCredits?employerId=${employerData.id}`
          );
          dispatch(
            setCredits({
              credits: creditsResponse.data.credits,
            })
          );
          setLoading(false);
        } catch (err) {
          setLoading(false);
          setError(
            "An error occured while fetching the credits. Please try again later"
          );
        }
      })();
    }
  }, [activeStep, dispatch, employerData.id]);

  const handlePrevious = () => {
    setError("");
    setInviteList([]);
    if (activeStep === 1) {
      if (activePreferenceTab === 0) {
        setActiveStep(0);
      } else {
        setActivePreferenceTab(activePreferenceTab - 1);
      }
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  const handleNext = async () => {
    setError("");
    if (activeStep === 0) {
      setLoading(true);
      await axios
        .post(`${XANO_API_BASE_URL}/emp_prefs/create_job`, {
          job_id: job.id,
          emp_id: employerData.id,
          job_title: jobTitle,
          Description: jobDescription,
          Client_Name: clientName,
        })
        .then((response) => {
          dispatch(
            setJob({
              id: response.data["job_id"],
              jobTitle,
              jobDescription,
              clientName,
            })
          );
          setTitle(jobTitle);
          setLoading(false);
          setActiveStep(activeStep + 1);
          window.scrollTo(0, 0);
        })
        .catch((err) => {
          setLoading(false);
          setError(
            "There was an error while creating the job. Please try again later."
          );
        });
    } else if (activeStep === 1) {
      if (activePreferenceTab === 0) {
        setLoading(true);
        axios
          .all([
            axios.post(`${XANO_API_BASE_URL}/jp_emp_preferences/company`, {
              company: company.formData,
              disabled: {
                companySize: company.formData.companySize,
                companyType: company.formData.companyType,
                companyKind: company.formData.companyKind,
              },
            }),
          ])
          .then(
            axios.spread((resp1) => {
              dispatch(
                setCompany({
                  ...company,
                })
              );
              setLoading(false);
              setActivePreferenceTab(activePreferenceTab + 1);
              window.scrollTo(0, 0);
            })
          )
          .catch((error) => {
            setError(
              "There was an error while saving the preferences. Please try again later."
            );
            setLoading(false);
          });
      }
      if (activePreferenceTab === 1) {
        setLoading(true);
        axios
          .all([
            axios.post(`${XANO_API_BASE_URL}/jp_emp_preferences/growth`, {
              growth: growth.formData,
              disabled: {
                growthKind: growth.formData.growthKind,
                growthSupport: growth.formData.growthSupport,
                growthHike: growth.formData.growthHike,
              },
            }),
          ])
          .then(
            axios.spread((resp1) => {
              dispatch(
                setGrowth({
                  ...growth,
                })
              );
              setLoading(false);
              setActivePreferenceTab(activePreferenceTab + 1);
              window.scrollTo(0, 0);
            })
          )
          .catch((error) => {
            setError(
              "There was an error while saving the preferences. Please try again later."
            );
            setLoading(false);
          });
      }
      if (activePreferenceTab === 2) {
        setLoading(true);
        axios
          .all([
            axios.post(`${XANO_API_BASE_URL}/jp_emp_preferences/location`, {
              location: {
                preferredCountries: location.formData.preferredCountries,
                preferences: location.formData.preferences,
                preferredMode: location.formData.preferredMode,
                preferredCities: location.formData.preferredCities,
                jobId: job.id,
              },
              disabled: {
                locPreferences: location.formData.locPreferences ? true : false,
                locPreferredCities: location.formData.locPreferredCities
                  ? true
                  : false,
                locPreferredMode: location.formData.locPreferredMode
                  ? true
                  : false,
                locPreferredCountries: location.formData.locPreferredCountries
                  ? true
                  : false,
              },
            }),
          ])
          .then(
            axios.spread((resp1) => {
              dispatch(
                setLocation({
                  ...location,
                })
              );
              setLoading(false);
              setActivePreferenceTab(activePreferenceTab + 1);
              window.scrollTo(0, 0);
            })
          )
          .catch((error) => {
            setError(
              "There was an error while saving the preferences. Please try again later."
            );
            setLoading(false);
          });
      }
      if (activePreferenceTab === 3) {
        setLoading(true);
        axios
          .all([
            axios.post(`${XANO_API_BASE_URL}/jp_emp_preferences/perks`, {
              perks: perks.formData,
              disabled: {
                perksKind: perks.formData.perksKind,
                perksAdditionalFacilities:
                  perks.formData.perksAdditionalFacilities,
              },
            }),
          ])
          .then(
            axios.spread((resp1) => {
              dispatch(
                setPerks({
                  ...perks,
                })
              );
              setLoading(false);
              setActivePreferenceTab(activePreferenceTab + 1);
              window.scrollTo(0, 0);
            })
          )
          .catch((error) => {
            setError(
              "There was an error while saving the preferences. Please try again later."
            );
            setLoading(false);
          });
      }
      if (activePreferenceTab === 4) {
        setLoading(true);
        axios
          .all([
            axios.post(`${XANO_API_BASE_URL}/jp_emp_preferences/role`, {
              role: role.formData,
              disabled: {
                preferredRole: role.formData.preferredRole,
                preferredEmployment: role.formData.preferredEmployment,
                preferredDesignation: role.formData.preferredDesignation,
              },
            }),
          ])
          .then(
            axios.spread((resp1) => {
              dispatch(
                setRole({
                  ...role,
                })
              );
              setLoading(false);
              setActivePreferenceTab(activePreferenceTab + 1);
              window.scrollTo(0, 0);
            })
          )
          .catch((error) => {
            setError(
              "There was an error while saving the preferences. Please try again later."
            );
            setLoading(false);
          });
      }
      if (activePreferenceTab === 5) {
        setLoading(true);
        axios
          .all([
            axios.post(`${XANO_API_BASE_URL}/jp_emp_preferences/salary`, {
              salary: salary.formData,
              disabled: {
                salaryRange: salary.formData.salaryRangeDisabled,
                salaryHike: salary.formData.salaryHikeDisabled,
                salaryComponents: salary.formData.salaryComponents,
              },
            }),
          ])
          .then(
            axios.spread((resp1) => {
              dispatch(
                setSalary({
                  ...salary,
                })
              );
              setLoading(false);
              setActivePreferenceTab(activePreferenceTab + 1);
              window.scrollTo(0, 0);
            })
          )
          .catch((error) => {
            setError(
              "There was an error while saving the preferences. Please try again later."
            );
            setLoading(false);
          });
      }

      if (activePreferenceTab === 6) {
        setLoading(true);
        axios
          .all([
            axios.post(`${XANO_API_BASE_URL}/jp_emp_preferences/work`, {
              work: work.formData,
              disabled: {
                workTime: work.formData.workTime,
                workShift: work.formData.workShift,
                workEnvironment: work.formData.workEnvironment,
                workKind: work.formData.workKind,
                Industry: work.formData.industryDisable,
              },
            }),
          ])
          .then(
            axios.spread((resp1) => {
              dispatch(
                setWork({
                  ...work,
                })
              );
              setLoading(false);
              setActiveStep(activeStep + 1);
              window.scrollTo(0, 0);
            })
          )
          .catch((error) => {
            setError(
              "There was an error while saving the preferences. Please try again later."
            );
            setLoading(false);
          });
      }
    } else if (activeStep === 2) {
      setLoading(true);
      setError("");
      axios
        .post(`${XANO_API_BASE_URL}/jp_emp_preferences/invites`, {
          job_id: job.id,
          invites: inviteList,
        })
        .then((response) => {
          axios
            .post(`${XANO_API_BASE_URL}/emp_prefs/credits`, {
              employerId: employerData.id,
              transactionType: "Debit",
              transactionAmount: inviteList.length * 20,
              updatedBalance: credits.credits - inviteList.length * 20,
              jobTitle,
            })
            .then(() => {
              axios
                .post(`${XANO_API_BASE_URL}/emp_prefs/change_job_status`, {
                  status: "Active",
                  job_id: job.id,
                  employer_id: employerData.id,
                })
                .then(() => {
                  setLoading(false);
                  setShowDialog(true);
                });
            })
            .catch((err) => {
              setLoading(false);
              setError(
                "There was an error while sending the invites. Please try again later."
              );
            });
        })
        .catch((err) => {
          setLoading(false);
          setError(
            "There was an error while sending the invites. Please try again later."
          );
        });
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {showDialog && (
        <Dialog
          title="Invites Sent"
          onClose={() => {
            window.location.reload();
          }}
        >
          <div className="create-job-invites-sent-dialog">
            <p className="create-job-invites-sent-dialog-text">
              Job Title : <b>{job.jobTitle}</b>
            </p>
            <p className="create-job-invites-sent-dialog-text">
              JS Selected : <b>{inviteList.length}</b>
            </p>
            <p className="create-job-invites-sent-dialog-text">
              Credits Spent : <b>{inviteList.length * 20} Credits</b>
            </p>
            <div style={{ display: "flex", gap: "1rem", alignSelf: "center" }}>
              <Button
                className="create-job-invites-sent-dialog-button"
                label="Add Another Job"
                onClick={() => {
                  window.location.reload();
                }}
              />
              <Button
                className="create-job-invites-sent-dialog-button"
                label="View All Jobs"
                onClick={() => {
                  window.location.reload();
                  setShowDialog(false);
                  navigate(DASHBOARD, { state: { trackJobs: true } });
                }}
              />
            </div>
          </div>
        </Dialog>
      )}
      {showCompleteDialog && (
        <Dialog
          title="Job Details Saved"
          onClose={() => {
            window.location.reload();
          }}
        >
          <div className="create-job-invites-sent-dialog">
            <p className="create-job-invites-sent-dialog-text">
              {job.jobTitle} has been successfully saved
            </p>
            <div style={{ display: "flex", gap: "1rem", alignSelf: "center" }}>
              <Button
                className="create-job-invites-sent-dialog-button"
                label="Add Another Job"
                onClick={() => {
                  window.location.reload();
                }}
              />
              <Button
                className="create-job-invites-sent-dialog-button"
                label="View All Jobs"
                onClick={() => {
                  window.location.reload();
                  setShowDialog(false);
                  navigate(DASHBOARD, { state: { trackJobs: true } });
                }}
              />
            </div>
          </div>
        </Dialog>
      )}
      <p className="create-job-title title-border">
        {headerTitle ? headerTitle : "Create a Job"}
      </p>
      {!hideTitle && <p className="create-job-title">{title}</p>}
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <div className="create-job-icon-button-container">
        <div
          onClick={() => {
            if (!(activeStep === 0 || loading)) {
              handlePrevious();
            }
          }}
        >
          <ArrowBackIosIcon
            color={activeStep === 0 || loading ? "disabled" : "action"}
            sx={{ cursor: activeStep === 0 || loading ? "default" : "pointer" }}
          />
        </div>
        <div
          onClick={() => {
            if (!nextButtonDisabled) {
              handleNext();
            }
          }}
        >
          <ArrowForwardIosIcon
            color={nextButtonDisabled ? "disabled" : "action"}
            sx={{ cursor: nextButtonDisabled ? "default" : "pointer" }}
          />
        </div>
      </div>
      {jobDataLoading && (
        <CircularProgress size={32} className="form-loading" />
      )}
      {!jobDataLoading && (
        <>
          <div className="create-job-content">
            {activeStep === 0 && (
              <JobDetails
                jobTitle={jobTitle}
                jobDescription={jobDescription}
                clientName={clientName}
                onDetailsChange={handleJobDetailsChange}
              />
            )}

            {activeStep === 1 && (
              <div>
                {activePreferenceTab === 0 && <Company />}
                {activePreferenceTab === 1 && <Growth />}
                {activePreferenceTab === 2 && <Location />}
                {activePreferenceTab === 3 && <Perks />}
                {activePreferenceTab === 4 && <Role />}
                {activePreferenceTab === 5 && <Salary />}
                {activePreferenceTab === 6 && <Work />}
              </div>
            )}
            {isActiveJob && activeStep === 2 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "1.5rem",
                  gap: "1rem",
                }}
              >
                <Button
                  label="Continue Without Invites"
                  onClick={() => {
                    setShowCompleteDialog(true);
                  }}
                />
                <p>(OR)</p>
              </div>
            )}
            {activeStep === 2 && (
              <>
                <SendInvites getInvitelist={getInvites} />
                <p style={{ marginBottom: "0.5rem" }}>
                  A preview of the email sent to the Jobseeker from Hiringhood
                  is shown below{" "}
                </p>
                <img
                  src={emailImage}
                  alt="Preview"
                  style={{
                    border: "1px solid #d6d6d6",
                    width: "100%",
                    height: "auto",
                  }}
                />
              </>
            )}
          </div>

          <div className="create-job-button-container">
            <div className="create-job-button-wrapper">
              <Button
                label="Previous"
                disabled={previousButtonDisabled}
                onClick={handlePrevious}
              />
              <Button
                label={activeStep === 2 ? "Send Invites" : "Next"}
                disabled={nextButtonDisabled}
                loading={loading}
                onClick={() => {
                  handleNext();
                }}
              />
            </div>
          </div>

          <p className="create-job-error">{error}</p>
        </>
      )}
    </div>
  );
};

export default CreateJob;
