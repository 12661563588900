import { SET_PREFERENCES } from "../actions/preferences";
import { JOB_SEEKER_PREFERENCES } from "../../utils/site-data";

const INITIAL_STATE = {
  preferences: JOB_SEEKER_PREFERENCES,
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_PREFERENCES:
      return {
        ...state,
        preferences: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
