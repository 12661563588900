import { useEffect, useState } from "react";
import axios from "../../utils/axios";
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import Dialog from "../Dialog";
import { XANO_API_BASE_URL } from "../../utils/api-urls";
import { useMediaQuery } from "../../utils/hooks";
import parseResponse from "../../utils/response-parser";
import "./styles.css";
import Button from "../Button";
import Slider from "../Slider";

const ViewResponses = (props: any) => {
  const { jobId, jsId, jsName, onClose } = props;
  const [loading, setLoading] = useState(true);
  const [preferences, setPreferences] = useState<any[]>([]);
  const [probability, setProbability] = useState("");
  const [optimisticProbability, setOptimisticProbability] = useState("");
  const [pessimisticProbability, setPessimisticProbability] = useState("");
  const [showConfigurationDialog, setShowConfigurationDialog] = useState(false);
  const [positiveScore, setPositiveScore] = useState(1);
  const [negativeScore, setNegativeScore] = useState(0);
  const [exponent, setExponent] = useState(1);
  const positiveMarks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 5,
      label: "5",
    },
    {
      value: 10,
      label: "10",
    },
    {
      value: 15,
      label: "15",
    },
  ];

  const negativeMarks = [
    {
      value: -15,
      label: "-15",
    },
    {
      value: -10,
      label: "-10",
    },
    {
      value: -5,
      label: "-5",
    },
    {
      value: 0,
      label: "0",
    },
  ];

  const fetchResponse = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${XANO_API_BASE_URL}/jp_js_emp_preferences?jobId=${jobId}&userId=${jsId}&exponent=${exponent}&positiveScore=${positiveScore}&negativeScore=${negativeScore}`
      );
      const {
        parsedResponse,
        probability,
        // optimisticProbability,
        // pessimisticProbability,
      } = parseResponse(response.data);
      setPreferences([...parsedResponse]);
      setProbability(probability);
      setOptimisticProbability(optimisticProbability);
      setPessimisticProbability(pessimisticProbability);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const largeDevice = useMediaQuery("(min-width:880px)");
  const mediumDevice = useMediaQuery("(min-width:672px)");
  const smallDevice = useMediaQuery("(min-width:500px)");

  const getWidth = () => {
    if (largeDevice) {
      return 400;
    } else if (mediumDevice) {
      return 300;
    } else if (smallDevice) {
      return 200;
    }

    return 200;
  };

  const handlePositiveScoreChange = (value: number) => {
    setPositiveScore(value);
  };

  const handleExponentChange = (value: number) => {
    setExponent(value);
  };

  const handleNegativeScoreChange = (value: number) => {
    setNegativeScore(value);
  };

  useEffect(() => {
    (async () => {
      fetchResponse();
    })();
  }, []);

  function Row(props: any) {
    const { row } = props;

    return (
      <>
        <TableRow>
          <TableCell style={{ textAlign: "center" }}>{row.title}</TableCell>
          <TableCell>
            {row.jsPreferences &&
              row.jsPreferences.map((preference: any) => {
                return (
                  <div style={{ marginBottom: "1rem" }}>
                    <p
                      className={`candidate-preferences-title ${
                        preference.isDealBreaker
                          ? "candidate-preferences-title-deal-breaker"
                          : ""
                      }`}
                    >
                      {preference.title}
                    </p>
                    <>
                      {preference.preferences.map((item: string) => {
                        return (
                          <p
                            className={
                              preference.isDealBreaker
                                ? "candidate-preferences-title-deal-breaker"
                                : ""
                            }
                          >
                            {item}
                          </p>
                        );
                      })}
                    </>
                  </div>
                );
              })}
          </TableCell>
          <TableCell>
            {row.employerPreferences &&
              row.employerPreferences.map((preference: any) => {
                return (
                  <div style={{ marginBottom: "1rem" }}>
                    <p className="candidate-preferences-title">
                      {preference.title}
                    </p>
                    <>
                      {preference.preferences.map((item: string) => {
                        return <p>{item}</p>;
                      })}
                    </>
                  </div>
                );
              })}
          </TableCell>
          <TableCell style={{ textAlign: "center" }}>
            <p>
              <b>{row.score}</b>
            </p>
          </TableCell>
        </TableRow>
      </>
    );
  }

  return (
    <>
      {showConfigurationDialog && (
        <Dialog
          title={`Edit Configuartion`}
          onClose={() => {
            setShowConfigurationDialog(false);
          }}
        >
          <div className="view-response-configuration-container">
            <p>Exponential Weight</p>
            <Slider
              marks={positiveMarks}
              min={0}
              max={15}
              step={1}
              width={getWidth()}
              sliderValue={exponent}
              onSliderChange={handleExponentChange}
            />
            <p style={{ marginTop: "1rem" }}>Score for a Positive Match</p>
            <Slider
              marks={positiveMarks}
              min={0}
              max={15}
              step={1}
              width={getWidth()}
              sliderValue={positiveScore}
              onSliderChange={handlePositiveScoreChange}
            />
            <p style={{ marginTop: "1rem" }}>Penalty for a Negative Match</p>
            <Slider
              marks={negativeMarks}
              min={-15}
              max={0}
              step={1}
              width={getWidth()}
              sliderValue={negativeScore}
              onSliderChange={handleNegativeScoreChange}
            />

            <Button
              className="view-response-configuration-button"
              label="Submit"
              onClick={() => {
                setShowConfigurationDialog(false);
                fetchResponse();
              }}
            />
          </div>
        </Dialog>
      )}
      <Dialog
        className="view-response-dialog"
        title={`Viewing Response for ${jsName}`}
        onClose={onClose}
      >
        <div
          title="Set Configuration"
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "1rem",
            right: "4rem",
          }}
          onClick={() => {
            setShowConfigurationDialog(true);
          }}
        >
          <SettingsIcon />
        </div>
        {loading && <CircularProgress size={32} className="form-loading" />}
        {!loading && (
          <>
            {/* <p
              style={{
                fontSize: "1.125rem",
                marginBottom: "1.5rem",
                color: "#330000",
              }}
            >
              Optimistic Probability:{" "}
              <b>
                <span>{optimisticProbability}</span>
              </b>
            </p> */}
            {/* <p
              style={{
                fontSize: "1.125rem",
                marginBottom: "1.5rem",
                color: "#330000",
              }}
            >
              Pessimistic Probability:{" "}
              <b>
                <span>{pessimisticProbability}</span>
              </b>
            </p> */}
            <p
              style={{
                fontSize: "1.125rem",
                marginBottom: "1.5rem",
              }}
            >
              Joining Probability:{" "}
              <b>
                <span>{probability}</span>
              </b>
            </p>
            <p
              style={{
                fontSize: "1.125rem",
                margin: "1rem 0 1.5rem 0",
                color: "#ff0000",
              }}
            >
              *Candidate preferences marked in red (if any) are non-negotiable
            </p>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  style={{ overflowX: "initial" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ textAlign: "center" }}>
                        <b>Preference</b>
                      </TableCell>
                      <TableCell>
                        <b>Candidate Preferences</b>
                      </TableCell>
                      <TableCell>
                        <b>Employer Preferences</b>
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <b>Score</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {preferences.map((row: any) => {
                      return <Row key={row.title} row={row} />;
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
        )}
      </Dialog>
    </>
  );
};

export default ViewResponses;
