import mappings from "./response-mapper";

const parseResponse = (response) => {
  const employerPreferences = response["preferences"]["empPreferences"];
  const jsPreferences = response["preferences"]["jsPreferences"];

  const parsedResponse = [];

  parsedResponse.push({
    title: "Company",
    employerPreferences: getCompanyPrefrencesForEmployer(employerPreferences),
    jsPreferences: getCompanyPrefrencesForJS(jsPreferences),
    score: `${
      Math.round(
        (response["preferences"]["probabilities"]["company_probability"] +
          Number.EPSILON) *
          100
      ) / 100
    }/100`,
  });

  parsedResponse.push({
    title: "Growth",
    employerPreferences: getGrowthPreferencesForEmployer(employerPreferences),
    jsPreferences: getGrowthPrefrencesForJS(jsPreferences),
    score: `${
      Math.round(
        (response["preferences"]["probabilities"]["growth_probability"] +
          Number.EPSILON) *
          100
      ) / 100
    }/100`,
  });

  parsedResponse.push({
    title: "Location",
    employerPreferences: getLocationPreferencesForEmployer(employerPreferences),
    jsPreferences: getLocationPrefrencesForJS(jsPreferences),
    score: `${
      Math.round(
        (response["preferences"]["probabilities"]["location_probability"] +
          Number.EPSILON) *
          100
      ) / 100
    }/100`,
  });

  parsedResponse.push({
    title: "Perks",
    employerPreferences: getPerksPreferencesForEmployer(employerPreferences),
    jsPreferences: getPerksPrefrencesForJS(jsPreferences),
    score: `${
      Math.round(
        (response["preferences"]["probabilities"]["perks_probability"] +
          Number.EPSILON) *
          100
      ) / 100
    }/100`,
  });

  parsedResponse.push({
    title: "Role",
    employerPreferences: getRolePreferencesForEmployer(employerPreferences),
    jsPreferences: getRolePrefrencesForJS(jsPreferences),
    score: `${
      Math.round(
        (response["preferences"]["probabilities"]["role_probability"] +
          Number.EPSILON) *
          100
      ) / 100
    }/100`,
  });

  parsedResponse.push({
    title: "Salary",
    employerPreferences: getSalaryPreferencesForEmployer(employerPreferences),
    jsPreferences: getSalaryPrefrencesForJS(jsPreferences),
    score: `${
      Math.round(
        (response["preferences"]["probabilities"]["salary_probability"] +
          Number.EPSILON) *
          100
      ) / 100
    }/100`,
  });

  parsedResponse.push({
    title: "Work",
    employerPreferences: getWorkPreferencesForEmployer(employerPreferences),
    jsPreferences: getWorkPrefrencesForJS(jsPreferences),
    score: `${
      Math.round(
        (response["preferences"]["probabilities"]["work_probability"] +
          Number.EPSILON) *
          100
      ) / 100
    }/100`,
  });

  return {
    parsedResponse,
    probability: `${Math.round(
      response["preferences"]["probabilities"]["probability"]
    )}%`,
    // optimisticProbability: `${Math.round(
    //   response["preferences"]["probabilities"]["optimistic"]["probability"]
    // )}/100`,
    // pessimisticProbability: `${Math.round(
    //   response["preferences"]["probabilities"]["pessimistic"]["probability"]
    // )}/100`,
  };
};

const getCompanyPrefrencesForEmployer = (preferences) => {
  const employerPreferences = [];

  Object.keys(preferences["company"]).forEach((key) => {
    if (key === "size") {
      const companyObj = {
        title: "Company Size",
        preferences: Object.keys(preferences["company"][key]).map((item) => {
          if (preferences["company"][key][item] && key !== "other_kind") {
            return mappings[item];
          } else if (item === "Other") {
            return preferences["company"][key]["Text_Field"];
          }
        }),
      };
      employerPreferences.push(companyObj);
    } else if (key === "type") {
      const companyObj = {
        title: "Company Type",
        preferences: Object.keys(preferences["company"][key]).map((item) => {
          if (
            preferences["company"][key][item] &&
            key !== "other_infrastructure"
          ) {
            return mappings[item];
          } else if (item === "Other") {
            return preferences["company"][key]["Text_Field"];
          }
        }),
      };
      employerPreferences.push(companyObj);
    } else {
      const companyObj = {
        title: "Office",
        preferences: Object.keys(preferences["company"][key]).map((item) => {
          if (
            preferences["company"][key][item] &&
            key !== "other_infrastructure"
          ) {
            return mappings[item];
          } else if (item === "Other") {
            return preferences["company"][key]["Text_Field"];
          }
        }),
      };
      employerPreferences.push(companyObj);
    }
  });

  return employerPreferences;
};

const getCompanyPrefrencesForJS = (preferences) => {
  const jsPreferences = [];
  const dealBreakers = preferences["dealBreakers"];

  Object.keys(preferences["company"]).forEach((key) => {
    if (key === "size") {
      const companyObj = {
        title: "Company Size",
        isDealBreaker: dealBreakers && dealBreakers.companyKind,
        preferences: Object.keys(preferences["company"][key]).map((item) => {
          if (preferences["company"][key][item]) {
            return mappings[item];
          }
        }),
      };
      jsPreferences.push(companyObj);
    } else if (key === "type") {
      const companyObj = {
        title: "Company Type",
        isDealBreaker: dealBreakers && dealBreakers.companyType,
        preferences: Object.keys(preferences["company"][key]).map((item) => {
          if (preferences["company"][key][item]) {
            return mappings[item];
          }
        }),
      };
      jsPreferences.push(companyObj);
    } else {
      const companyObj = {
        title: "Office",
        isDealBreaker: dealBreakers && dealBreakers.companySize,
        preferences: Object.keys(preferences["company"][key]).map((item) => {
          if (preferences["company"][key][item]) {
            return mappings[item];
          }
        }),
      };
      jsPreferences.push(companyObj);
    }
  });

  return jsPreferences;
};

const getGrowthPreferencesForEmployer = (preferences) => {
  const employerPreferences = [];

  Object.keys(preferences["growth"]).forEach((key) => {
    if (key === "kind") {
      const growthObj = {
        title: "Kind of Growth",
        preferences: Object.keys(preferences["growth"][key]).map((item) => {
          if (preferences["growth"][key][item] && key !== "other_kind") {
            return mappings[item];
          } else if (item === "Other_kind") {
            return preferences["growth"][key]["Text_Field"];
          }
        }),
      };
      employerPreferences.push(growthObj);
    } else if (key === "support") {
      const growthObj = {
        title: "Support for Growth",
        preferences: Object.keys(preferences["growth"][key]).map((item) => {
          if (
            preferences["growth"][key][item] &&
            key !== "other_infrastructure"
          ) {
            return mappings[item];
          } else if (item === "support_other") {
            return preferences["growth"][key]["support_text_field"];
          }
        }),
      };
      employerPreferences.push(growthObj);
    }
  });

  employerPreferences.push({
    title: "Hike",
    preferences: [
      preferences["growth"]["hike"]["min_hike"] +
        "% - " +
        preferences["growth"]["hike"]["max_hike"] +
        "%",
    ],
  });

  return employerPreferences;
};

const getGrowthPrefrencesForJS = (preferences) => {
  const jsPreferences = [];
  const dealBreakers = preferences["dealBreakers"];

  Object.keys(preferences["growth"]).forEach((key) => {
    if (key !== "hike") {
      if (key === "kind") {
        const growthObj = {
          title: "Kind of Growth",
          isDealBreaker: dealBreakers && dealBreakers.growthKind,
          preferences: Object.keys(preferences["growth"][key]).map((item) => {
            if (preferences["growth"][key][item]) {
              return mappings[item];
            }
          }),
        };
        jsPreferences.push(growthObj);
      } else {
        const growthObj = {
          title: "Support for Growth",
          isDealBreaker: dealBreakers && dealBreakers.growthSupport,
          preferences: Object.keys(preferences["growth"][key]).map((item) => {
            if (preferences["growth"][key][item]) {
              return mappings[item];
            }
          }),
        };
        jsPreferences.push(growthObj);
      }
    }
  });

  jsPreferences.push({
    title: "Hike",
    isDealBreaker: dealBreakers && dealBreakers.growthHike,
    preferences: [
      preferences["growth"]["hike"]["min_hike"] +
        "% - " +
        preferences["growth"]["hike"]["max_hike"] +
        "%",
    ],
  });

  return jsPreferences;
};

const getLocationPreferencesForEmployer = (preferences) => {
  const employerPreferences = [];

  employerPreferences.push({
    title: "Mode",
    preferences: [
      preferences["loc_preferences"]["preference1"] +
        ", " +
        preferences["loc_preferences"]["preference2"] +
        ", " +
        preferences["loc_preferences"]["preference3"] +
        ", " +
        preferences["loc_preferences"]["preference4"],
    ],
  });

  const locations = [];
  if (preferences["loc_preferred_mode"]["Metro"]) {
    locations.push("Metro");
  }
  if (preferences["loc_preferred_mode"]["tier1_city"]) {
    locations.push("Tier 1 City");
  }
  if (preferences["loc_preferred_mode"]["tier2_city"]) {
    locations.push("Tier 2 City");
  }
  if (preferences["loc_preferred_mode"]["small_town"]) {
    locations.push("Small Town");
  }

  employerPreferences.push({
    title: "Locations",
    preferences: [locations.join(", ")],
  });

  const cities = preferences["loc_preferred_cities"].map((city) => {
    return city.city;
  });

  employerPreferences.push({
    title: "Cities",
    preferences: [cities.join(", ")],
  });

  const countries = preferences["loc_preferred_countries"].map((country) => {
    return country.country;
  });

  employerPreferences.push({
    title: "Countries",
    preferences: [countries.join(", ")],
  });

  return employerPreferences;
};

const getLocationPrefrencesForJS = (preferences) => {
  const jsPreferences = [];
  const dealBreakers = preferences["dealBreakers"];

  jsPreferences.push({
    title: "Mode",
    isDealBreaker: dealBreakers && dealBreakers.locPreferredMode,
    preferences: [
      preferences["loc_preferences"]["preference1"] +
        ", " +
        preferences["loc_preferences"]["preference2"] +
        ", " +
        preferences["loc_preferences"]["preference3"] +
        ", " +
        preferences["loc_preferences"]["preference4"],
    ],
  });

  const locations = [];
  if (preferences["loc_preferred_mode"]["Metro"]) {
    locations.push("Metro");
  }
  if (preferences["loc_preferred_mode"]["tier1_city"]) {
    locations.push("Tier 1 City");
  }
  if (preferences["loc_preferred_mode"]["tier2_city"]) {
    locations.push("Tier 2 City");
  }
  if (preferences["loc_preferred_mode"]["small_town"]) {
    locations.push("Small Town");
  }

  jsPreferences.push({
    title: "Locations",
    isDealBreaker: dealBreakers && dealBreakers.locPreferences,
    preferences: [locations.join(", ")],
  });

  const cities = preferences["loc_preferred_cities"].map((city) => {
    return city.city;
  });

  jsPreferences.push({
    title: "Cities",
    isDealBreaker: dealBreakers && dealBreakers.locPreferredCities,
    preferences: [cities.join(", ")],
  });

  const countries = preferences["loc_preferred_countries"].map((country) => {
    return country.country;
  });

  jsPreferences.push({
    title: "Countries",
    isDealBreaker: dealBreakers && dealBreakers.locPreferredCountries,
    preferences: [countries.join(", ")],
  });

  return jsPreferences;
};

const getPerksPreferencesForEmployer = (preferences) => {
  const employerPreferences = [];

  Object.keys(preferences["perks"]).forEach((key) => {
    if (key === "kind") {
      const perksObj = {
        title: "Kind of Perks",
        preferences: Object.keys(preferences["perks"][key]).map((item) => {
          if (preferences["perks"][key][item] && key !== "other_kind") {
            return mappings[item];
          } else if (item === "other_kind") {
            return preferences["perks"][key]["kind_text_field"];
          }
        }),
      };
      employerPreferences.push(perksObj);
    } else {
      const perksObj = {
        title: "Additional Facilities",
        preferences: Object.keys(preferences["perks"][key]).map((item) => {
          if (
            preferences["perks"][key][item] &&
            key !== "other_infrastructure"
          ) {
            return mappings[item];
          } else if (item === "other_infrastructure") {
            return preferences["perks"][key]["infrasctructure_text_feild"];
          }
        }),
      };
      employerPreferences.push(perksObj);
    }
  });

  return employerPreferences;
};

const getPerksPrefrencesForJS = (preferences) => {
  const jsPreferences = [];
  const dealBreakers = preferences["dealBreakers"];

  Object.keys(preferences["perks"]).forEach((key) => {
    if (key === "kind") {
      const perksObj = {
        title: "Kind of Perks",
        isDealBreaker: dealBreakers && dealBreakers.perksKind,
        preferences: Object.keys(preferences["perks"][key]).map((item) => {
          if (preferences["perks"][key][item]) {
            return mappings[item];
          }
        }),
      };
      jsPreferences.push(perksObj);
    } else {
      const perksObj = {
        title: "Additional facilities",
        isDealBreaker: dealBreakers && dealBreakers.perksAdditionalFacilities,
        preferences: Object.keys(preferences["perks"][key]).map((item) => {
          if (preferences["perks"][key][item]) {
            return mappings[item];
          }
        }),
      };
      jsPreferences.push(perksObj);
    }
  });

  return jsPreferences;
};

const getRolePreferencesForEmployer = (preferences) => {
  const employerPreferences = [];

  employerPreferences.push({
    title: "Role",
    preferences: preferences["role"]["role_other"]
      ? [preferences["role"]["role_text_field"]]
      : [mappings[preferences["role"]["role"]]],
  });

  employerPreferences.push({
    title: "Employment Type",
    preferences: preferences["role"]["employment_other"]
      ? [preferences["role"]["employment_text_field"]]
      : [mappings[preferences["role"]["employment"]]],
  });
  employerPreferences.push({
    title: "Designation",
    preferences: preferences["role"]["designation_other"]
      ? [preferences["role"]["designation_text_field"]]
      : [mappings[preferences["role"]["designation"]]],
  });

  return employerPreferences;
};

const getRolePrefrencesForJS = (preferences) => {
  const jsPreferences = [];
  const dealBreakers = preferences["dealBreakers"];

  jsPreferences.push({
    title: "Role",
    isDealBreaker: dealBreakers && dealBreakers.preferredRole,
    preferences: [mappings[preferences["role"]["preferred_role"]]],
  });

  jsPreferences.push({
    title: "Employment Type",
    isDealBreaker: dealBreakers && dealBreakers.preferredEmployment,
    preferences: [mappings[preferences["role"]["preferred_employment"]]],
  });

  return jsPreferences;
};

const getSalaryPreferencesForEmployer = (preferences) => {
  const employerPreferences = [];

  const salaryComponents = [];
  Object.keys(preferences["salary"]).forEach((key) => {
    if (
      key !== "Other" &&
      key !== "min_hike" &&
      key !== "max_hike" &&
      key !== "min_range" &&
      key !== "max_range" &&
      preferences["salary"][key]
    ) {
      salaryComponents.push(mappings[key]);
    }
    if (key === "Other") {
      salaryComponents.push(preferences["salary"]["Text_Field"]);
    }
  });

  employerPreferences.push({
    title: "Salary Hike",
    preferences: [
      preferences["salary"]["min_hike"] +
        "% - " +
        preferences["salary"]["max_hike"] +
        "%",
    ],
  });

  employerPreferences.push({
    title: "Salary Range",
    preferences: [
      preferences["salary"]["min_range"] +
        "LPA - " +
        preferences["salary"]["max_range"] +
        "LPA",
    ],
  });

  employerPreferences.push({
    title: "Salary Components",
    preferences: [salaryComponents.join(", ")],
  });

  return employerPreferences;
};

const getSalaryPrefrencesForJS = (preferences) => {
  const jsPreferences = [];
  const dealBreakers = preferences["dealBreakers"];

  const salaryComponents = [];
  Object.keys(preferences["salary"]).forEach((key) => {
    if (
      key !== "min_salary_hike" &&
      key !== "max_salary_hike" &&
      key !== "min_salary_range" &&
      key !== "max_salary_range" &&
      key !== "Salary_Range" &&
      key !== "Salary_Hike" &&
      preferences["salary"][key]
    ) {
      salaryComponents.push(mappings[key]);
    }
  });

  jsPreferences.push({
    title: "Salary Hike",
    isDealBreaker: dealBreakers && dealBreakers.salaryHike,
    preferences: [preferences["salary"]["Salary_Hike"]],
  });

  jsPreferences.push({
    title: "Salary Range",
    isDealBreaker: dealBreakers && dealBreakers.salaryRange,
    preferences: [preferences["salary"]["Salary_Range"]],
  });

  jsPreferences.push({
    title: "Salary Components",
    isDealBreaker: dealBreakers && dealBreakers.salaryComponents,
    preferences: [salaryComponents.join(", ")],
  });

  return jsPreferences;
};

const getWorkPreferencesForEmployer = (preferences) => {
  const employerPreferences = [];

  Object.keys(preferences["work"]).forEach((key) => {
    if (key !== "Industry") {
      if (key === "time") {
        const workOnj = {
          title: "Work Timings",
          preferences: Object.keys(preferences["work"][key]).map((item) => {
            if (preferences["work"][key][item] && key !== "other_kind") {
              return mappings[item];
            } else if (item === "other_time") {
              return preferences["work"][key]["time_text_field"];
            }
          }),
        };
        employerPreferences.push(workOnj);
      } else if (key === "shift") {
        const workOnj = {
          title: "Shift",
          preferences: Object.keys(preferences["work"][key]).map((item) => {
            if (
              preferences["work"][key][item] &&
              key !== "other_infrastructure"
            ) {
              return mappings[item];
            } else if (item === "other_shift") {
              return preferences["work"][key]["shift_text_field"];
            }
          }),
        };
        employerPreferences.push(workOnj);
      } else if (key === "environment") {
        const workOnj = {
          title: "Work Environment",
          preferences: Object.keys(preferences["work"][key]).map((item) => {
            if (preferences["work"][key][item] && key !== "other_environment") {
              return mappings[item];
            } else if (item === "other_infrastructure") {
              return preferences["work"][key]["environment_text_feild"];
            }
          }),
        };
        employerPreferences.push(workOnj);
      } else {
        const workOnj = {
          title: "Kind of Work",
          preferences: Object.keys(preferences["work"][key]).map((item) => {
            if (
              preferences["work"][key][item] &&
              key !== "other_infrastructure"
            ) {
              return mappings[item];
            } else if (item === "other_exceiting_work") {
              return preferences["work"][key]["exciting_work_test_feild"];
            }
          }),
        };
        employerPreferences.push(workOnj);
      }
    }
  });

  employerPreferences.push({
    title: "Industry",
    preferences: [preferences["work"]["Industry"]],
  });

  return employerPreferences;
};

const getWorkPrefrencesForJS = (preferences) => {
  const jsPreferences = [];
  const dealBreakers = preferences["dealBreakers"];

  Object.keys(preferences["work"]).forEach((key) => {
    if (key !== "Industry") {
      if (key === "time") {
        const workObj = {
          title: "Work Timings",
          isDealBreaker: dealBreakers && dealBreakers.workTime,
          preferences: Object.keys(preferences["work"][key]).map((item) => {
            if (preferences["work"][key][item]) {
              return mappings[item];
            }
          }),
        };
        jsPreferences.push(workObj);
      } else if (key === "shift") {
        const workObj = {
          title: "Shift",
          isDealBreaker: dealBreakers && dealBreakers.workShift,
          preferences: Object.keys(preferences["work"][key]).map((item) => {
            if (preferences["work"][key][item]) {
              return mappings[item];
            }
          }),
        };
        jsPreferences.push(workObj);
      } else if (key === "environment") {
        const workObj = {
          title: "Work Environment",
          isDealBreaker: dealBreakers && dealBreakers.workEnvironment,
          preferences: Object.keys(preferences["work"][key]).map((item) => {
            if (preferences["work"][key][item]) {
              return mappings[item];
            }
          }),
        };
        jsPreferences.push(workObj);
      } else {
        const workObj = {
          title: "Kind of Work",
          isDealBreaker: dealBreakers && dealBreakers.workKind,
          preferences: Object.keys(preferences["work"][key]).map((item) => {
            if (preferences["work"][key][item]) {
              return mappings[item];
            }
          }),
        };
        jsPreferences.push(workObj);
      }
    }
  });

  if (
    preferences["work"]["Industry"] !== "anyIndustry" &&
    preferences["work"]["Industry"] !== "notSure"
  ) {
    jsPreferences.push({
      title: "Industry",
      isDealBreaker: dealBreakers && dealBreakers.Industry,
      preferences: [preferences["work"]["Industry"]],
    });
  } else if (preferences["work"]["Industry"] === "notSure") {
    jsPreferences.push({
      title: "Industry",
      isDealBreaker: dealBreakers && dealBreakers.Industry,
      preferences: ["Not sure"],
    });
  } else {
    jsPreferences.push({
      title: "Industry",
      isDealBreaker: dealBreakers && dealBreakers.Industry,
      preferences: ["Any industry"],
    });
  }

  return jsPreferences;
};

export default parseResponse;
