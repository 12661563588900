import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Form from "../Form";
import { roleSelector, jobSelector } from "../../redux/selectors";
import { roleQuestions } from "../../utils/constants";
import { setRole } from "../../redux/actions/role";

const Role = () => {
  const { role } = useSelector(roleSelector);
  const { job } = useSelector(jobSelector);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const handleChange = (formData: any, isValid: boolean) => {
    dispatch(
      setRole({
        ...role,
        formData: { ...role.formData, ...formData, jobId: job.id },
        isConfigured: isValid,
      })
    );
  };

  useEffect(() => {
    if (!role.isConfigured) {
      setError("You need to select atleast one preference from each section");
    } else {
      setError("");
    }
  }, [role.isConfigured]);

  return (
    <>
      <p className="form-title">Role</p>
      <Form
        id="role"
        questions={roleQuestions}
        handleChange={handleChange}
        formData={role.formData}
      />
      <p className="form-error-message">{error}</p>
    </>
  );
};

export default Role;
