import { combineReducers } from "redux";
import userReducer from "./user";
import preferencesReducer from "./preferences";
import growthReducer from "./growth";
import roleReducer from "./role";
import salaryReducer from "./salary";
import perksReducer from "./perks";
import workReducer from "./work";
import locationReducer from "./location";
import companyReducer from "./company";
import jobReducer from "./job";
import creditsReducer from "./credits";

const reducer = combineReducers({
  preferences: preferencesReducer,
  user: userReducer,
  growth: growthReducer,
  role: roleReducer,
  salary: salaryReducer,
  perks: perksReducer,
  work: workReducer,
  location: locationReducer,
  company: companyReducer,
  job: jobReducer,
  credits: creditsReducer,
});

export default reducer;
