import { SET_JOB } from "../actions/job";

const INITIAL_STATE = {
  job: {},
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_JOB:
      return {
        ...state,
        job: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
