import { useState } from "react";
import * as XLSX from "xlsx";
import Input from "../../components/Input";
import Button from "../Button";
import Tag from "../Tag";
import { validateEmail } from "../../utils/validators";
import "./styles.scss";

const SendInvites = (props: any) => {
  const { getInvitelist } = props;
  const [email, setEmail] = useState("");
  const [emailIds, setEmailIds] = useState<string[]>([]);
  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(true);

  const handleEmail = (emailAddress: string) => {
    setEmail(emailAddress);
    if (!emailAddress) {
      setError("Email cannot be empty");
      setDisabled(true);
    } else if (!validateEmail(emailAddress)) {
      setError("Enter a vaild email address");
      setDisabled(true);
    } else {
      setError("");
      setDisabled(false);
    }
  };

  const handleAddEmail = () => {
    setError("");
    const existingEmails = JSON.parse(JSON.stringify(emailIds));
    if (!existingEmails.includes(email)) {
      existingEmails.push(email);
    } else {
      setError(`Email address '${email}' has already been added`);
    }
    setEmailIds([...existingEmails]);
    setEmail("");
    setDisabled(true);
    getInvitelist(existingEmails);
  };

  const handleClose = (emailId: string) => {
    setError("");
    let existingEmails = JSON.parse(JSON.stringify(emailIds));
    existingEmails = existingEmails.filter((email: string) => {
      return email !== emailId;
    });
    setEmailIds([...existingEmails]);
    getInvitelist(existingEmails);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    setEmailIds([]);
    const file = event.target?.files?.[0];
    if (!file) {
      return;
    }
    const fileType = file.type;
    if (
      fileType !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      fileType !== "application/vnd.ms-excel"
    ) {
      setError("Invalid file type. Only .xlsx and .xls files are allowed");
      return;
    }
    const reader = new FileReader();
    const existingEmails = JSON.parse(JSON.stringify(emailIds));
    reader.onload = (e: any) => {
      const data = new Uint8Array(e.target?.result);
      if (!data) {
        return;
      }
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      excelData.forEach((item: any) => {
        existingEmails.push(item[0]);
      });
      setEmailIds([...existingEmails]);
      getInvitelist(existingEmails);
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <>
      <p className="send-invites-title">Add Job Seeker(s)</p>
      <div className="send-invites-input-container">
        <p className="send-invites-text">Enter Job Seeker Email address: </p>
        <Input
          className="send-invites-input"
          label="Email Address*"
          value={email}
          onChange={(event) => {
            handleEmail(event.target.value);
          }}
          onBlur={(event) => {
            handleEmail(event.target.value);
          }}
        />
        <Button
          className="send-invites-add-email"
          disabled={disabled}
          label="Add"
          onClick={handleAddEmail}
        />
      </div>
      <div style={{ marginTop: "1.5rem" }}>
        Or Upload an Excel file with the email addresses
      </div>
      <input type="file" onChange={handleFileUpload} className="file-input" />
      <p className="form-error-message">{error}</p>
      <div className="send-invites-tags-container">
        {emailIds.map((emailid) => {
          return (
            <div className="send-invites-tag">
              <Tag
                text={emailid}
                onClose={() => {
                  handleClose(emailid);
                }}
              />
            </div>
          );
        })}
      </div>
      {emailIds.length > 0 && (
        <div>
          <hr className="send-invites-separator" />
          <div className="send-invites-text send-invites-credit-text">
            <span>Total JS Selected</span>
            {emailIds.length}
          </div>
          <div className="send-invites-text send-invites-credit-text">
            <span>Credits per JS</span>
            <span>X 20 Credits </span>
          </div>
          <hr className="send-invites-separator" />
          <div className="send-invites-text send-invites-credit-text">
            <span>Total Credits</span>
            <span>{emailIds.length * 20} Credits</span>
          </div>
        </div>
      )}
    </>
  );
};

export default SendInvites;
