import { useState } from "react";
import Input from "../../components/Input";
import "./styles.scss";

const JobDetails = (props: any) => {
  const { jobTitle, jobDescription, clientName, onDetailsChange } = props;

  const [title, setTitle] = useState(jobTitle);
  const [description, setDescription] = useState(jobDescription);
  const [name, setName] = useState(clientName);

  const handleTitle = (title: string) => {
    setTitle(title);
    onDetailsChange(title, description, clientName);
  };

  const handleDescription = (description: string) => {
    setDescription(description);
    onDetailsChange(title, description, clientName);
  };

  const handleClientName = (clientName: string) => {
    setName(clientName);
    onDetailsChange(title, description, clientName);
  };

  return (
    <>
      <p className="job-details-title">Enter Job Details</p>
      <Input
        className="job-details-input"
        label="Client Name"
        value={name}
        onChange={(event) => {
          handleClientName(event?.target.value);
        }}
        onBlur={(event) => {
          handleClientName(event?.target.value);
        }}
      />
      <Input
        className="job-details-input"
        label="Job Title"
        value={title}
        onChange={(event) => {
          handleTitle(event?.target.value);
        }}
        onBlur={(event) => {
          handleTitle(event?.target.value);
        }}
      />
      <Input
        className="job-details-input"
        label="Job Description"
        value={description}
        onChange={(event: any) => {
          handleDescription(event?.target.value);
        }}
        onBlur={(event: any) => {
          handleDescription(event?.target.value);
        }}
        rows={10}
      />
    </>
  );
};

export default JobDetails;
